<template>
  <v-app>
    <Nav></Nav>
    <v-row class="bannerlogo rowpage2" style="display: flex; justify-content: center; ">
      <v-col cols="12" sm="12" md="4" lg="5" xl="5" class="bannerIz">

        <div class="text">
          <div class="la-portabilidad-llega-al-mundo-de-los-seguros-de-desgravamen">
            <p>La portabilidad llega al mundo de los seguros<br />de desgravamen.
            </p>
          </div>
          <div class="recibe-dinero-directamente-en-tu-cuenta-bancaria">
            Recibe dinero directamente en tu cuenta bancaria
          </div>
          <div class="boton-saber-mas" @click="como">
            <div class="component-53">
              <div class="submit2">¿Cómo funciona?</div>
            </div>
          </div>
        </div>
        <v-img class="mt-10" src="/assets/group-290.svg" cover></v-img>
        <!-- <img class="group-29 mt-12 img-fluid" src="/assets/group-290.svg"> -->

      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="bannerCn text-center mt-12">
        <img class="piggy-1" src="/assets/piggy-10.png">
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="bannerDr">

        <div class="quieres-portar-tu-seguro-de-desgravamen">
          Si tienes un seguro de desgravamen asociado a un crédito de consumo, automotriz
          o comercial (PYME), lo puedes cambiar y recuperar dinero.
        </div>
    
        <div class="btn-contrata">

          <v-btn  rounded outlined large color="rgb(3 75 178)" @click="calcula" class="mr-1 txtbnt6"><span
              class="home-text txtbnt7"><span>Calcula tu devolución</span></span></v-btn>
        
        </div>
        <div class="btn-contrata">
          <v-btn rounded large class="mr-1 txtbnt5" @click="contratar"><span class="home-text txtbnt4"><span>CONTRATAR
                VIA WEB</span></span></v-btn>
        </div>
        <div class="excluye-cr-ditos-hipotecarios">
          *Excluye créditos hipotecarios.
        </div>

      </v-col>
      <v-col cols="12" sm="12" md="10" lg="10" xl="10">
      <!-- <v-row style="display: flex; justify-content: center; ">
        <v-col cols="9" sm="9" md="9" lg="9" xl="9" > -->
          <div
            class="mueve-seguro-act-a-conforme-a-la-circular-2-114-de-fecha-26-07-2013-de-la-cmf-ley-19-496-art-17-d-ley-20-448-art-8">
            Mueve Seguro actúa conforme a la Circular 2.114 de fecha 26-07-2013 de la CMF, Ley 19.496 Art. 17D, Ley 20.448 Art. 8. Por su parte, el nuevo seguro de Desgravamen es intermediado por Insurex Corredores de Seguros SPA, quienes se encuentran debidamente registrados ante la CMF.
          </div>
        <!-- </v-col>
      </v-row> -->
    </v-col>
    </v-row>
    <v-row>

      <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="rowpage2"></v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="rowpage2"></v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="rowpage2 ">
        <div class="bienvenidos-a-la-portabilidad-de-seguros-de-desgravamen mt-8">
          Bienvenidos a la Portabilidad de Seguros de Desgravamen
        </div>
        <v-row class="text-center pdrotate">
          <v-col cols="12" sm="12" md="12" lg="12" xl="12"></v-col>
          <v-col cols="12" sm="12" md="12" lg="12" xl="12"></v-col>
          <v-col cols="12" sm="12" md="6" lg="3" xl="3" class="colcenter ">
            <div class="frame-8" @mouseover="rotation(1)" @mouseout="rotation(1)" :class="{ 'frame-8-2': isMouseRot1 }">
              <div class="frame-11">
                <div class="rotate-container">
                  <img class="group imagetra" :class="{ onmouserot: isMouseRot1 }" src="/assets/group0.svg">
                </div>
                <div class="nosotros-gestionamos-todo" :class="{ 'nosotros-gestionamos-todo-2': isMouseRot1 }">
                  <span>
                    <span class="nosotros-gestionamos-todo-span"
                      :class="{ 'nosotros-gestionamos-todo-span-2': isMouseRot1 }">
                      Nosotros
                      <br>
                    </span>
                    <span class="nosotros-gestionamos-todo-span2"
                      :class="{ 'nosotros-gestionamos-todo-span2-2': isMouseRot1 }">
                      gestionamos todo
                    </span>
                  </span>
                </div>
              </div>
              <div class="al-portar-tu-seguro-con-nosotros-nos-preocupamos-de-hacer-todos-los-tr-mites-por-ti"
                :class="{ 'al-portar-tu-seguro-con-nosotros-nos-preocupamos-de-hacer-todos-los-tr-mites-por-ti-2': isMouseRot1 }">
                Nosotros gestionamos la renuncia para recibir la devolución de la prima no consumida                
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="3" xl="3" class="colcenter">
            <div class="frame-9" @mouseover="rotation(2)" @mouseout="rotation(2)" :class="{ 'frame-8-2': isMouseRot2 }">
              <div class="frame-10">
                <img class="group imagetra" :class="{ onmouserot: isMouseRot2 }" src="/assets/group0.svg">
                <div class="paga-un-precio-justo" :class="{ 'nosotros-gestionamos-todo-2': isMouseRot2 }">
                  <span>
                    <span class="paga-un-precio-justo-span"
                      :class="{ 'nosotros-gestionamos-todo-span-2': isMouseRot2 }">
                      Paga un
                      <br>
                    </span>
                    <span class="paga-un-precio-justo-span2"
                      :class="{ 'nosotros-gestionamos-todo-span2-2': isMouseRot2 }">precio justo</span>
                  </span>
                </div>
              </div>
              <div class="ten-la-misma-cobertura-de-siempre-pero-a-un-precio-conveniente-y-sin-pagar-de-m-s"
                :class="{ 'nosotros-gestionamos-todo-span2-2': isMouseRot2 }">
                Ten la misma cobertura de siempre, pero a un precio conveniente
                y sin pagar de más.
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="3" xl="3" class="colcenter">
            <div class="frame-102" @mouseover="rotation(3)" @mouseout="rotation(3)"
              :class="{ 'frame-8-2': isMouseRot3 }">
              <div class="frame-10">
                <img class="group imagetra" :class="{ onmouserot: isMouseRot3 }" src="/assets/group0.svg">
                <div class="ten-tu-cashback" :class="{ 'nosotros-gestionamos-todo-span-2': isMouseRot3 }">
                  <span>
                    <span class="ten-tu-cashback-span" :class="{ 'nosotros-gestionamos-todo-span-2': isMouseRot3 }">¡Ten
                      tu</span>
                    <span class="ten-tu-cashback-span2" :class="{ 'nosotros-gestionamos-todo-span-2': isMouseRot3 }">
                      &nbsp;
                      <br>
                    </span>
                    <span class="ten-tu-cashback-span3"
                      :class="{ 'nosotros-gestionamos-todo-span-2': isMouseRot3 }">cashback!</span>
                  </span>
                </div>
              </div>
              <div class="al-portarte-te-devolveremos-lo-que-pagaste-de-m-s-directo-a-tu-cuenta-bancaria"
                :class="{ 'nosotros-gestionamos-todo-span-2': isMouseRot3 }">
                Al portarte, te devolveremos lo que pagaste de más directo a tu
                cuenta bancaria.&nbsp;
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="3" xl="3" class="colcenter">
            <div class="frame-112" @mouseover="rotation(4)" @mouseout="rotation(4)"
              :class="{ 'frame-8-2': isMouseRot4 }">
              <div class="frame-10">
                <img class="group imagetra" :class="{ onmouserot: isMouseRot4 }" src="/assets/group0.svg">
                <div class="siempre-estar-s-protegido" :class="{ 'nosotros-gestionamos-todo-span-2': isMouseRot4 }">
                  <span>
                    <span class="siempre-estar-s-protegido-span"
                      :class="{ 'nosotros-gestionamos-todo-span-2': isMouseRot4 }">
                      Siempre estarás
                      <br>
                    </span>
                    <span class="siempre-estar-s-protegido-span2"
                      :class="{ 'nosotros-gestionamos-todo-span-2': isMouseRot4 }">
                      protegido
                    </span>
                  </span>
                </div>
              </div>
              <div class="nos-aseguramos-de-que-al-momento-de-realizar-tu-cambio-jam-s-te-quedes-sin-cobertura"
                :class="{ 'nosotros-gestionamos-todo-span-2': isMouseRot4 }">
                A través de la asesoría en materia de seguros de Insurex Corredores, obtienes la nueva póliza manteniendo la cobertura de desgravamen a un precio justo
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="display: flex; justify-content: center; ">
        <v-row style="display: flex; justify-content: center; ">
          <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="display: flex; justify-content: center; ">
            <div id="cfunciona" class="c-mo-funciona4">¿Cómo funciona?</div>
          </v-col>
          <!-- <v-col cols="1" sm="1" md="1" lg="1" xl="1" style="display: flex; justify-content: center; ">

          </v-col> -->
          <v-col cols="12" sm="12" md="5" lg="5" xl="5" style="display: flex; justify-content: center; ">
            <v-row style="display: flex; justify-content: center; ">
              <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="display: flex; justify-content: center; ">
                <div class="calcula-tu-devolucion-text">
                  <img class="group-12" src="/assets/group-120.svg">
                  <div
                    class="calcula-tu-devoluci-n-simula-en-l-nea-con-los-datos-de-tu-cr-dito-actual-y-calcula-cu-nto-dinero-podr-as-ahorrar-o-recuperar">
                    <span>
                      <span
                        class="calcula-tu-devoluci-n-simula-en-l-nea-con-los-datos-de-tu-cr-dito-actual-y-calcula-cu-nto-dinero-podr-as-ahorrar-o-recuperar-span">
                        Calcula tu devolución:

                      </span>
                      <span
                        class="calcula-tu-devoluci-n-simula-en-l-nea-con-los-datos-de-tu-cr-dito-actual-y-calcula-cu-nto-dinero-podr-as-ahorrar-o-recuperar-span2">
                        <br>
                        Simula en línea con los datos de tu crédito actual y calcula cuánto
                        dinero podrías ahorrar o recuperar.
                      </span>
                    </span>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="display: flex; justify-content: center; ">
                <div class="ingresa-text">
                  <img class="group-11" src="/assets/group-110.svg">
                  <div class="reg-strate-completa-el-registro-para-que-podamos-aprobar-tu-solicitud">
                    <span>
                      <span class="reg-strate-completa-el-registro-para-que-podamos-aprobar-tu-solicitud-span">
                        Regístrate:

                      </span>
                      <span class="reg-strate-completa-el-registro-para-que-podamos-aprobar-tu-solicitud-span2">
                        <br>
                      </span>
                      <span class="reg-strate-completa-el-registro-para-que-podamos-aprobar-tu-solicitud-span3">
                        Completa el registro para que podamos aprobar tu solicitud.
                      </span>
                    </span>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="display: flex; justify-content: center; ">
                <div class="adjunta-text">
                  <img class="group-30" src="/assets/group-300.svg">
                  <div class="adjunta-tus-documentos-adjunta-los-antecedentes-de-tu-cr-dito-y-seguro-vigente">
                    <span>
                      <span class="adjunta-tus-documentos-adjunta-los-antecedentes-de-tu-cr-dito-y-seguro-vigente-span">
                        Adjunta tus documentos:

                      </span>
                      <span
                        class="adjunta-tus-documentos-adjunta-los-antecedentes-de-tu-cr-dito-y-seguro-vigente-span2">
                        <br>
                      </span>
                      <span
                        class="adjunta-tus-documentos-adjunta-los-antecedentes-de-tu-cr-dito-y-seguro-vigente-span3">
                        Adjunta los antecedentes de tu crédito y seguro vigente.
                      </span>
                    </span>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="display: flex; justify-content: center; ">
                <div class="firma-text">
                  <img class="group-9" src="/assets/group-90.svg">
                  <div
                    class="firma-haz-tu-firma-digital-en-el-mandato-y-o-carta-de-renuncia-para-autorizarnos-a-gestionar-tu-portabilidad">
                    <span>
                      <span
                        class="firma-haz-tu-firma-digital-en-el-mandato-y-o-carta-de-renuncia-para-autorizarnos-a-gestionar-tu-portabilidad-span">
                        Firma:

                      </span>
                      <span
                        class="firma-haz-tu-firma-digital-en-el-mandato-y-o-carta-de-renuncia-para-autorizarnos-a-gestionar-tu-portabilidad-span2">
                        <br>
                      </span>
                      <span
                        class="firma-haz-tu-firma-digital-en-el-mandato-y-o-carta-de-renuncia-para-autorizarnos-a-gestionar-tu-portabilidad-span3">
                        Haz tu firma digital en el mandato y/o carta de renuncia para
                        autorizarnos a gestionar tu portabilidad.
                      </span>
                    </span>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="display: flex; justify-content: center; ">
                <div class="beneficios-text">
                  <img class="group-8" src="/assets/group-80.svg">
                  <div
                    class="disfruta-tu-beneficio-tu-nuevo-seguro-se-paga-con-parte-de-la-devoluci-n-de-prima-no-consumida-y-recuperas-directo-a-tu-cuenta-el-saldo-a-favor">
                    <span>
                      <span
                        class="disfruta-tu-beneficio-tu-nuevo-seguro-se-paga-con-parte-de-la-devoluci-n-de-prima-no-consumida-y-recuperas-directo-a-tu-cuenta-el-saldo-a-favor-span">
                        Disfruta tu beneficio:

                      </span>
                      <span
                        class="disfruta-tu-beneficio-tu-nuevo-seguro-se-paga-con-parte-de-la-devoluci-n-de-prima-no-consumida-y-recuperas-directo-a-tu-cuenta-el-saldo-a-favor-span2">
                        <br>
                      </span>
                      <span
                        class="disfruta-tu-beneficio-tu-nuevo-seguro-se-paga-con-parte-de-la-devoluci-n-de-prima-no-consumida-y-recuperas-directo-a-tu-cuenta-el-saldo-a-favor-span3">
                        Tu nuevo seguro se paga con parte de la devolución de prima no
                        consumida y recuperas directo a tu cuenta el saldo a favor.
                      </span>
                    </span>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6" style="display: flex; justify-content: center; "
            class="rotateimage">
            <div class="carrusel2">
              <div class="frame-6">
                <v-carousel hide-delimiter-background show-arrows-on-hover height="651" width="888" color="black">
                  <v-carousel-item >
                    <video autoplay muted onload="this.play()" controls="controls" class="size-video" name="Video prensa de Mueve Seguro">
                                    <source src="/media/prensa.mov">
                                </video>      
                      
                  </v-carousel-item>
                 <!-- <v-carousel-item height="651" width="888" v-for="(item, i) in items" :key="i"
                    :src="item.src"></v-carousel-item>-->
                   
                   
                </v-carousel>

                
                <div class="rectangle-27" ></div>
                <div class="ingresa" @click="contratar">Ingresa</div>

              </div>

            </div>

          </v-col>
          <v-col cols="1" sm="1" md="1" lg="1" xl="1" style="display: flex; justify-content: right; "
            class="rotateimage">
            <img class="vector" src="/assets/vector0.svg">
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="row3">
      <v-col cols="12" sm="12" md="2" lg="1" xl="1 " class="circuloiz" style="display: flex; justify-content: center; ">
        <img class="vector4" src="/assets/vector3.svg">
      </v-col>
      <v-col cols="12" sm="12" md="8" lg="10" xl="10 " style="display: flex; justify-content: center; ">
        <v-row class="row3">
          <v-col cols="12" sm="12" md="12" lg="12" xl="12 " style="display: flex; justify-content: center; "></v-col>
          <v-col cols="12" sm="12" md="12" lg="12" xl="12 " style="display: flex; justify-content: center; "></v-col>
          <v-col cols="12" sm="12" md="12" lg="12" xl="12 " style="display: flex; justify-content: center; ">
            <span
              class="es-posible-la-portabilidad-del-seguro-de-desgravamen-claro-que-s-mueve-seguro-actua-conforme-a-normas-y-leyes-vigentes-span">
              ¿Es posible la portabilidad del seguro de desgravamen?
            </span>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12" xl="12 " style="display: flex; justify-content: center; ">
            <span
              class="es-posible-la-portabilidad-del-seguro-de-desgravamen-claro-que-s-mueve-seguro-actua-conforme-a-normas-y-leyes-vigentes-span2">
              ¡Claro que sí! Mueve Seguro actua conforme a normas y leyes vigentes.
            </span>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12" xl="12 " style="display: flex; justify-content: center; "></v-col>
          <v-col cols="12" sm="12" md="8" lg="4" xl="4" style="display: flex; justify-content: center; ">
            <div class="ley-1">
              <img class="crop-ethnic-businessman-signing-papers-2023-11-27-05-18-25-utc"
                src="/assets/crop-ethnic-businessman-signing-papers-2023-11-27-05-18-25-utc0.png">
              <div class="rectangle-23"></div>
              <div class="circular-2114-26-07-2013-cmf">
                Circular 2114 26/07/2013 CMF&nbsp;&nbsp;
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="8" lg="4" xl="4" style="display: flex; justify-content: center; ">
            <div class="ley-2">
              <img class="asian-woman-designer-working-with-laptop-in-creati-2023-11-27-05-04-40-utc"
                src="/assets/asian-woman-designer-working-with-laptop-in-creati-2023-11-27-05-04-40-utc0.png">
              <div class="rectangle-24"></div>
              <div class="ley-19-496-art-17-d">Ley 19.496 Art. 17D</div>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="8" lg="4" xl="4" style="display: flex; justify-content: center; ">
            <div class="ley-3">
              <img class="female-advisor-attorney-lawyer-consulting-client-i-2024-02-02-18-20-30-utc"
                src="/assets/female-advisor-attorney-lawyer-consulting-client-i-2024-02-02-18-20-30-utc0.png">
              <div class="rectangle-25"></div>
              <div class="ley-20-448-art-8">Ley 20.448 Art.8</div>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="2" lg="1" xl="1" class="circulodr" style="display: flex; justify-content: center; ">
        <img class="vector3" src="/assets/vector2.svg">

      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12 " style="display: flex; justify-content: center; "></v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12 " style="display: flex; justify-content: center; "></v-col>
    </v-row>
    <v-row class="">
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="display: flex; justify-content: center; ">

      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="display: flex; justify-content: center; ">

      </v-col>

      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="display: flex; justify-content: center; ">
        <div class="frame-23">
          <div class="requisitos-de-portabilidad">Requisitos de portabilidad</div>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="display: flex; justify-content: center; ">
        <v-row class="" style="display: flex; justify-content: center; ">
          <!-- <v-col cols="3" sm="3" md="3" lg="3" xl="3" style="display: flex; justify-content: center; "></v-col> -->
          <v-col cols="8" sm="8" md="8" lg="8" xl="8" style="display: flex; justify-content: center; ">
            <v-row class="" style="display: flex; justify-content: center; ">
              <!-- <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="display: flex; justify-content: center; "></v-col> -->
              <v-col cols="12" sm="12" md="6" lg="4" xl="4" style="display: flex; justify-content: center; ">

                <div class="group-16">
                  <div class="rectangle-30">
                    <v-row class="">
                      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="display: flex; justify-content: center; ">
                        <div>
                          <v-avatar color="#4695e4" size="70" class="avrow3">
                            <span class="white--text text-h5">1</span>
                          </v-avatar>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="display: flex; justify-content: center; ">
                        <div>
                          <div
                            class="tener-un-cr-dito-vigente-con-seguro-de-degravamen-en-bancos-cooperativas-o-automotoras">
                            Tener un crédito vigente con seguro de degravamen en bancos,
                            cooperativas o automotoras (*).
                            <br>
                            <br>
                            <br>
                          </div>
                        </div>
                      </v-col>
                    </v-row>

                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="4" xl="4" style="display: flex; justify-content: center; ">
                <div class="group-16">
                  <div class="rectangle-30">
                    <v-row class="">
                      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="display: flex; justify-content: center; ">
                        <div>
                          <v-avatar color="#4695e4" size="70" class="avrow3">
                            <span class="white--text text-h5">2</span>
                          </v-avatar>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="display: flex; justify-content: center; ">
                        <div>
                          <div
                            class="tener-un-cr-dito-vigente-con-seguro-de-degravamen-en-bancos-cooperativas-o-automotoras">
                            Cédula de identidad vigente.
                            <br>
                            <br>
                            <br>
                          </div>
                        </div>
                      </v-col>
                    </v-row>

                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="4" xl="4" style="display: flex; justify-content: center; ">
                <div class="group-16">
                  <div class="rectangle-30">
                    <v-row class="">
                      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="display: flex; justify-content: center; ">
                        <div>
                          <v-avatar color="#4695e4" size="70" class="avrow3">
                            <span class="white--text text-h5">3</span>
                          </v-avatar>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="display: flex; justify-content: center; ">
                        <div>
                          <div
                            class="tener-un-cr-dito-vigente-con-seguro-de-degravamen-en-bancos-cooperativas-o-automotoras">
                            Antecedentes de tu crédito y seguro vigente.
                            <br>
                            <br>
                            <br>
                          </div>
                        </div>
                      </v-col>
                    </v-row>

                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col cols="3" sm="3" md="3" lg="3" xl="3" style="display: flex; justify-content: center; "></v-col> -->
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="display: flex; justify-content: center; ">
        <div class="excluye-cr-ditos-hipotecarios2">
          (*Excluye créditos hipotecarios)
        </div>
      </v-col>
    </v-row>
    <v-row class="row3">

      <v-col cols="12" sm="12" md="12" lg="12" xl="12 " style="display: flex; justify-content: center; ">
        <div class="frame-22">
          <div class="historias-de-xito">Historias de Éxito</div>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12 " style="display: flex; justify-content: center; "></v-col>

      <v-col cols="10" sm="10" md="10" lg="10" xl="10 " style="display: flex; justify-content: center; ">
        <v-row class="" style="display: flex; justify-content: center; ">

          <v-col cols="12" sm="12" md="8" lg="4" xl="4 " style="display: flex; justify-content: center; ">
            <div class="rectangle-28">
              <v-row class="">
                <v-col cols="12" sm="12" md="12" lg="12" xl="12 " style="display: flex; justify-content: center; ">
                  <div class="texto_historia">
                    "Me porté a Mueve Seguro y por un crédito &nbsp;de 26 millones en 36
                    cuotas, que aún no empezaba a pagar, recibí una devolución de
                    $322.000".
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="4" lg="4" xl="4 " style="display: flex; justify-content: right; ">
                  <img class="ka-4-z-1-we-7-tf-hu-dall-e-create-1"
                    src="/assets/ka-4-z-1-we-7-tf-hu-dall-e-create-10.png">
                </v-col>
                <v-col cols="8" sm="8" md="8" lg="8" xl="8 " style="display: flex; justify-content: center; ">
                  <div class="nombre_historia">Alejandra Torres.</div>
                </v-col>
              </v-row>

            </div>
          </v-col>
          <v-col cols="12" sm="12" md="8" lg="4" xl="4 " style="display: flex; justify-content: center; ">
            <div class="rectangle-28">
              <v-row class="">
                <v-col cols="12" sm="12" md="12" lg="12" xl="12 " style="display: flex; justify-content: center; ">
                  <div class="texto_historia">
                    "Por un crédito de 30 millones en 60 cuotas, que me quedaban 45 cuotas por pagar, recibí una
                    devolución de $446.000".
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="4" lg="4" xl="4 " style="display: flex; justify-content: right; ">
                  <img class="ka-4-z-1-we-7-tf-hu-dall-e-create-1" src="/assets/a-xs-2-rql-o-qbgx-dall-e-create-10.png">
                </v-col>
                <v-col cols="8" sm="8" md="8" lg="8" xl="8 " style="display: flex; justify-content: center; ">
                  <div class="nombre_historia">Carlos Ruiz.</div>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="8" lg="4" xl="4 " style="display: flex; justify-content: center; ">
            <div class="rectangle-28">
              <v-row class="">
                <v-col cols="12" sm="12" md="12" lg="12" xl="12 " style="display: flex; justify-content: center; ">
                  <div class="texto_historia">
                    "Por un crédito de 49 millones en 60 cuotas, que me quedaban 41 cuotas por pagar, recibí una
                    devolución de 1 millón 400 mil pesos".
                  </div>
                </v-col>
                <v-col cols="4" sm="4" md="4" lg="4" xl="4 " style="display: flex; justify-content: right; ">
                  <img class="ka-4-z-1-we-7-tf-hu-dall-e-create-1" src="/assets/a-xs-2-rql-o-qbgx-dall-e-create-20.png">
                </v-col>
                <v-col cols="8" sm="8" md="8" lg="8" xl="8 " style="display: flex; justify-content: center; ">
                  <div class="nombre_historia">Felipe Campos.</div>
                </v-col>
              </v-row>
            </div>
          </v-col>

        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12 " style="display: flex; justify-content: center; "></v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12 " style="display: flex; justify-content: center; "></v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12 " style="display: flex; justify-content: center; "></v-col>

    </v-row>
    <v-row class="" style="display: flex; justify-content: center;">
      <v-col cols="12" sm="12" md="12" lg="12" xl="12 " style="display: flex; justify-content: center; "></v-col>
      <!--  <v-col cols="12" sm="12" md="12" lg="12" xl="12 " style="display: flex; justify-content: center; ">
        <div class="frame-24">
          <div class="compa-as-que-nos-respaldan">
            Compañías que nos respaldan&nbsp;
          </div>
        </div>
      </v-col>-->
      <v-col cols="12" sm="12" md="12" lg="12" xl="12 " style="display: flex; justify-content: center; "></v-col>

      <v-col cols="6" sm="6" md="6" lg="6" xl="6 " style="display: flex; justify-content: center; ">
        <v-row class="" style="display: flex; justify-content: center; ">
          <!--  <v-col cols="12" sm="12" md="8" lg="6" xl="6 " style="display: flex; justify-content: center; ">
            <img class="_59-b-53143-d-9-d-72" src="/assets/_59-b-53143-d-9-d-720.png">
          </v-col>-->
          <!--<v-col cols="12" sm="12" md="8" lg="6" xl="6 " style="display: flex; justify-content: center; ">
            <img class="group-14" src="/assets/group-140.svg">
          </v-col> -->
          <!-- <v-col cols="12" sm="12" md="8" lg="4" xl="4 " style="display: flex; justify-content: center; ">
            <img class="trayectoria-2018" src="/assets/trayectoria-20180.png">
          </v-col> -->
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12 " style="display: flex; justify-content: center; "></v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12 " style="display: flex; justify-content: center; "></v-col>
    </v-row>
    <v-row class="row3 colcenter">
      <v-col cols="12" sm="12" md="6" lg="3" xl="3 " style="display: flex; justify-content: center; ">
        <v-row class="row3 " style="display: flex; justify-content: center; ">
          <v-col cols="12" sm="12" md="12" lg="12" xl="12 " style="display: flex; justify-content: center; ">

            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6" xl="6 " style="display: flex; justify-content: center; ">
                <div class="una-empresa-de">Una empresa de</div>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6" xl="6 "
                style="display: flex; justify-content: center;    align-self: center; ">
                <img class="logotipo-grupo-insurex-blanco-1" src="/assets/logotipo-grupo-insurex-blanco-10.png">
              </v-col>
            </v-row>

          </v-col>

        </v-row>

      </v-col>
      <v-col cols="12" sm="12" md="6" lg="2" xl="2 " style="display: flex; justify-content: center; margin-top:10px ">
        <div class="mueve-seguro-ingresa-registrate">
          <span>
            <span class="mueve-seguro-ingresa-registrate-span">
              Mueve Seguro
              <br>
            </span>
            <span class="mueve-seguro-ingresa-registrate-span2"><br></span>
            <span class="mueve-seguro-ingresa-registrate-span3">
              Ingresa
              <br>
              Registrate
            </span>
          </span>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="2" xl="2" style="display: flex; justify-content: center;; margin-top:10px ">
        <div class="seguro-cotiza-beneficios-qu-es">
          <span>
            <span class="seguro-cotiza-beneficios-qu-es-span">
              Seguro
              <br>
            </span>
            <span class="seguro-cotiza-beneficios-qu-es-span2"><br></span>
            <span class="seguro-cotiza-beneficios-qu-es-span3">
              Cotiza
              <br>
              ¿Qué es?
            </span>
          </span>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="3" xl="3 "
        style="display: flex; justify-content: center;; margin-top:10px   ">
        <div class="group-34">
          <div class="group-32">
            <div class="contacto-comun-cate-con-nosotros-en-servicioalcliente-mueveseguro-cl">
              <span>
                <span class="contacto-comun-cate-con-nosotros-en-servicioalcliente-mueveseguro-cl-span">
                  Contacto
                  <br>
                </span>
                <span class="contacto-comun-cate-con-nosotros-en-servicioalcliente-mueveseguro-cl-span2">
                  Consultas de la portabilidad: Servicioalcliente@mueveseguro.cl
                  Consulta de tu nuevo Seguro: contacto@grupoinsurex.cl
                 
                </span>
              </span>
            </div>
          </div>
          <!-- <img class="group-33" src="/assets/group-330.svg"> -->
          <v-row class="row3 colcenter">
            <v-col cols="2" sm="2" md="2" lg="2" xl="2">
              <v-btn small color="#04137f" fab dark @click="face" id="btn-social-facebook">
                <v-icon >mdi-facebook</v-icon>
              </v-btn>

            </v-col>
            <v-col cols="2" sm="2" md="2" lg="2" xl="2">
              <v-btn small color="#04137f" fab dark @click="insta" id="btn-social-instagram">
                <v-icon >mdi-instagram</v-icon>
              </v-btn>

            </v-col>
            <v-col cols="2" sm="2" md="2" lg="2" xl="2">
              <v-btn small color="#04137f" fab dark @click="link" id="btn-social-linkedin">
                <v-icon >mdi-linkedin</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="2" sm="2" md="2" lg="2" xl="2">
              <v-btn small color="#04137f" fab dark @click="you" id="btn-social-youtube">
                <v-icon >mdi-youtube</v-icon>
              </v-btn>
            </v-col>
          </v-row>


        </div>
      </v-col>

      <v-col cols="12" sm="12" md="6" lg="2" xl="2" style="display: flex; justify-content: center;; margin-top:10px ">
        <div class="sobre-nosotros-somos-misi-n-visi-n-valores" @click="somos">
          <span>
            <span class="sobre-nosotros-somos-misi-n-visi-n-valores-span">
              Quiénes somos
              <br>
            </span>
            <span class="sobre-nosotros-somos-misi-n-visi-n-valores-span2">
              Mueve Seguro es una empresa de Grupo Insurex.
              Lee más sobre nosotros, nuestros valores y misión acá.
            </span>
          </span>
        </div>
      </v-col>
      <BotonWsp></BotonWsp>
      <BotonClick></BotonClick>
     <!-- <Media></Media> -->
    </v-row>

  </v-app>

</template>

<script>
import Nav from "./layout/Nav.vue";
import BotonWsp from "./components/BotonWsp.vue";
import BotonClick from "./components/BotonClick.vue";
//import Media from "./components/Media.vue";

export default {
  components: {
    Nav: Nav,
    BotonWsp: BotonWsp,
    BotonClick: BotonClick,
  //  Media: Media
  },
  data() {
    return {
      isMouseRot1: false,
      isMouseRot2: false,
      isMouseRot3: false,
      isMouseRot4: false,
      toggleMenu: false,
      drawer: false,
      group: null,
      items: [
        {
          src: '/assets/person-working-on-a-laptop-in-a-cozy-home-office-2024-03-01-16-35-10-utc-10.png',
        },
        {
          src: '/assets/couple-using-tablet-computer-2023-11-27-05-32-54-utc-10.png',
        },
        {
          src: '/assets/he-gets-the-team-up-and-running-2023-11-27-04-56-45-utc-10.png',
        },
        {
          src: '/assets/young-boss-hiring-new-colleague-shaking-hands-afte-2023-11-27-05-25-00-utc-10.png',
        },
      ],
    }
  },
  methods: {
    face() {
      window.open("https://www.facebook.com/profile.php?id=61555367385086", "");
    },
    insta() {
      window.open("https://www.instagram.com/mueveseguro/", "");
    },
    link() {
      window.open("https://www.linkedin.com/company/grupo-insurex/?viewAsMember=true", "");
    },
    you() {
      window.open("https://www.youtube.com/@mueveseguro", "");
    },
    calcula() {
      location.href = "https://landing.mueveseguro.cl/calculadora";
    },
    como() {
      location.href = "#cfunciona";
    },
    somos() {
      // this.$router.push("/nosotros");
      location.href = "/nosotros";
    },
    login() {
      location.href = "/login";
    },
    contratar() {
      location.href = "/formulario-cotizacion";
      // this.$router.push("/formulario-cotizacion");
    },
    rotation(opt) {
      if (opt == 1) {
        this.isMouseRot1 = !this.isMouseRot1
      }
      if (opt == 2) {
        this.isMouseRot2 = !this.isMouseRot2
      }
      if (opt == 3) {
        this.isMouseRot3 = !this.isMouseRot3
      }
      if (opt == 4) {
        this.isMouseRot4 = !this.isMouseRot4
      }


    }
  },
  watch: {
    // group() {
    //   this.drawer = false
    // },
  },
  computed: {
    showMenu() {
      return this.toggleMenu || this.$vuetify.breakpoint.mdAndUp
    },

  },
  mounted(){
    //this.$root.$refs.ModalMedia.dialogMedia = true;
  },
  created() {

    // this.traeInfoLegal();
    //this.traepFrcuente();
    this.$store.commit("setemptyform1", {});
    this.$store.commit("setemptyform2", {});
    this.$store.commit("setemptyform4", {});
    this.$store.commit('setemptyforme1', {});
    this.$store.commit('setemptyforme2', {});
    this.$store.commit("setclienteencontrado", false);
    this.$store.commit("setislogin", false);
    localStorage.removeItem("loginEstado");
    localStorage.removeItem("loginCliente");

    this.$store.commit("setemptyform1", {});
    this.$store.commit("setemptyform2", {});
    this.$store.commit("setemptyforme1", {});
    this.$store.commit("setemptyforme2", {});
    this.$store.commit("setemptyform3", {});
    this.$store.commit("setemptyform4", {});
    this.$store.commit("setemptyform5", {});
    this.$store.commit("setestadoFormLinea", 1);
    this.$store.commit("setestadoFormCotizacion", 1);
    this.$store.commit("setestadoBps", 1);
    this.$store.commit("setclienteencontrado", false);
    this.$store.commit('setrepresentanteencontrado', false);
    // this.$store.commit("settipocotizacion", "inicio");
    // this.$store.commit("settipopersonaempresa", "inicio");
    // this.$store.commit("setiniciodisabled", true);
    this.isLogin = false;
    this.$store.commit("setislogin", false);
    localStorage.removeItem('loginEstado');
    localStorage.removeItem('loginCliente');

    this.$store.commit('setclienteencontrado', false);
    this.$store.commit('setrepresentanteencontrado', false);
    if (this.$root.$refs.LineaForm1) {
      this.$root.$refs.LineaForm1.tipodisabled = true;
    }
    if (this.$root.$refs.CotizaForm1) {
      this.$root.$refs.CotizaForm1.tipodisabled = true;
    }

  },
}
</script>
<style scoped>

.img-principal{
  width: 100px;
  height: 100px;
}
.imagetra {
  transition: transform 0.5s ease-in-out;
  align-self: flex-start;
}

.onmouserot {
  transform: rotate(360deg);
}

/* .btntop{
  background: transparent !important;
    border: 0px !important;
    box-shadow: none !important;
} */
.HeadingsH6 {
  font-size: 15px;
  font-style: normal;
  font-family: Circular Pro;
  font-weight: 300px;
  font-stretch: normal;
  letter-spacing: 0em;
  margin-top: 21px !important;
}

.HeadingsH1 {
  width: 100px;
}

.home-text {
  top: 4px;
  color: rgba(92, 94, 116, 1);
  /* width: 100px; */
  height: auto;
  /* position: absolute; */
  text-align: center;
  line-height: 24px;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
}

#toolbar {
  padding: 27px !important;
  height: 118px !important;
}

.menutop {
  min-width: 100% !important;
  ;
  top: 38px !important;
  ;
  left: 0px !important;
  ;
  transform-origin: left top !important;
  ;
  z-index: 8 !important;
  ;
  height: auto !important;
  ;
}

.txtbnt {
  font-size: 13px !important;
  font-weight: 700 !important;
}

.txtbnt2 {
  font-size: 11px !important;
  padding-top: 26px !important;
  ;
  padding-bottom: 26px !important;
  ;
  background-color: rgba(4, 19, 127, 1) !important;
}

.txtbnt3 {
  font-size: 11px !important;
  padding-top: 26px !important;
  ;
  padding-bottom: 26px !important;
  ;
  /* background-color: rgba(4, 19, 127, 1) !important; */
  color: white !important;
}

.txtbnt5 {
  font-size: 13px !important;
  font-weight: 700;
  padding-top: 26px !important;
  ;
  padding-bottom: 26px !important;
  ;
  background-color: rgb(29 46 255) !important;
  color: white !important;
}

.txtbnt4 {
  font-size: 13px !important;
  font-weight: 700;
  padding-top: 26px !important;
  ;
  padding-bottom: 26px !important;
  ;
  /* background-color: rgb(3 75 178) !important;  */
  color: white !important;
}

.txtbnt6 {
  font-size: 13px !important;
  font-weight: 700;
  padding-top: 26px !important;
  ;
  padding-bottom: 26px !important;
  ;
  background-color: white !important;
  /* color: white !important; */
  text-transform: unset !important;

}

.txtbnt7 {
  font-size: 26px !important;
  font-weight: 500;
  /* padding: 28px; */
  /* padding-top: 28px !important;
  padding-bottom: 28px !important; */
  /* background-color: rgb(3 75 178) !important;  */
  color: rgba(4, 19, 127, 1) !important;

}

.btntop {
  margin-top: 3px;
}

.bannerlogo {
  background-image: url('/assets/teaser-ms-02-10.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  margin-left: 0px !important;
}


.la-portabilidad-llega-al-mundo-de-los-seguros-de-desgravamen {
  color: var(--blanco, #f0f2f2);
  text-align: left;
  font-family: var(--headings-h1-font-family, "CircularPro-Bold", sans-serif);
  font-size: 40px;
  line-height: 45px;
  font-weight: 600;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/* .la-portabilidad-llega-al-mundo-de-los-seguros-de-desgravamen {
  color: var(--blanco, #f0f2f2);
  text-align: left;
  font-family: var(--headings-h1-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h1-font-size, 64px);
  line-height: var(--headings-h1-line-height, 72px);
  font-weight: var(--headings-h1-font-weight, 700);
  position: relative;
  width: 800px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
} */

.recibe-dinero-directamente-en-tu-cuenta-bancaria {
  color: var(--celeste, #00e5ff);
  text-align: left;
  font-family: "CircularPro-Bold", sans-serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 700;
  position: relative;
  /* width: 867px; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.boton-saber-mas {
  border-radius: 28px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
}

.component-53 {
  background: var(--azul-primario, #1d2eff);
  border-radius: 30px;
  padding: 12px 16px 12px 16px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.group-29 {
  flex-shrink: 0;
  width: 621.45px;
  height: 130.31px;
  position: relative;
  overflow: visible;
}

.piggy-1 {
  flex-shrink: 0;
  width: 388px;
  height: 388px;
  /* position: relative; */
  object-fit: cover;
  align-content: center;
  margin-top: 54px;

}

.quieres-portar-tu-seguro-de-desgravamen {
  color: #ffffff;
  text-align: center;
  font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h2-font-size, 25px);
  line-height: var(--headings-h2-line-height, 32px);
  font-weight: var(--headings-h2-font-weight, 400);
  position: relative;
  /* width: 560px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.si-tienes-un-seguro-de-desgravamen-asociado-a-un-cr-dito-de-consumo-automotriz-o-comercial-pyme-y-quieres-portarte-y-recuperar-dinero-d-janos-tus-datos {
  color: #ffffff;
  text-align: center;
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 22px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
  position: relative;
  /* width: 560px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.boton-calcula {
  border-radius: 28px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  box-shadow: 5px 5px 6.3px 0px rgba(0, 0, 0, 0.5);
}

.component-54 {
  background: var(--blanco, #f0f2f2);
  border-radius: 30px;
  border-style: solid;
  border-color: var(--azul-complementario, #04137f);
  border-width: 1px;
  padding: 12px 16px 12px 16px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.submit3 {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h4-font-size, 36px);
  line-height: var(--headings-h4-line-height, 36px);
  font-weight: var(--headings-h4-font-weight, 400);
  position: relative;
  display: flex;
  justify-content: center;
}

.excluye-cr-ditos-hipotecarios {
  color: var(--blanco, #f0f2f2);
  text-align: center;
  font-family: var(--headings-h7-font-family, "CircularPro-Black", sans-serif);
  font-size: var(--headings-h7-font-size, 16px);
  line-height: var(--headings-h7-line-height, 24px);
  font-weight: var(--headings-h7-font-weight, 900);
  position: relative;
  margin-top: 30px;
  /* width: 400px; */
}

.mueve-seguro-act-a-conforme-a-la-circular-2-114-de-fecha-26-07-2013-de-la-cmf-ley-19-496-art-17-d-ley-20-448-art-8 {
  color: var(--blanco, #f0f2f2);
  text-align: center;
  font-family: var(--text-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--text-font-size, 16px);
  line-height: var(--text-line-height, 24px);
  font-weight: var(--text-font-weight, 400);
  position: relative;
  /* width: 1920px; */
  /* height: 60px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: -72px; */
}

.bienvenidos-a-la-portabilidad-de-seguros-de-desgravamen {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h2-font-size, 35px);
  line-height: var(--headings-h2-line-height, 60px);
  font-weight: var(--headings-h2-font-weight, 700);
  position: relative;
  /* width: 1469px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.frame-8 {
  background: #d9d9d9;
  border-radius: 20px;
  padding: 12px 10px 12px 10px;
  display: flex;
  flex-direction: row;
  gap: 21px;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 360px;
  height: 220px;
  position: relative;
}

.frame-8-2 {
  background: #04137f !important;
  ;
}

.frame-11 {
  display: flex;
  flex-direction: row;
  gap: 21px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  width: 100%;
}


.group {
  flex-shrink: 0;
  width: 72px;
  height: 72px;
  position: relative;
  overflow: visible;
}

.nosotros-gestionamos-todo {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  position: relative;
  width: 260px;
}

.nosotros-gestionamos-todo-2 {
  color: white !important;
}

.nosotros-gestionamos-todo-span {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h4-font-size, 30px);
  line-height: var(--headings-h4-line-height, 36px);
  font-weight: var(--headings-h4-font-weight, 400);
}

.nosotros-gestionamos-todo-span-2 {
  color: white !important;
}

.nosotros-gestionamos-todo-span2-2 {
  color: white !important;
}

.nosotros-gestionamos-todo-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Black", sans-serif;
  font-size: 25px;
  line-height: 28px;
  font-weight: 900;
}

.al-portar-tu-seguro-con-nosotros-nos-preocupamos-de-hacer-todos-los-tr-mites-por-ti-2 {
  color: white !important;
}

.al-portar-tu-seguro-con-nosotros-nos-preocupamos-de-hacer-todos-los-tr-mites-por-ti {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
  position: relative;
  width: 320px;
  height: 80px;
}

.frame-9 {
  background: #d9d9d9;
  border-radius: 20px;
  padding: 12px 10px 12px 10px;
  display: flex;
  flex-direction: row;
  gap: 21px;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 360px;
  height: 220px;
  position: relative;
}

.frame-10 {
  display: flex;
  flex-direction: row;
  gap: 21px;
  align-items: left;
  justify-content: left;
  flex-shrink: 0;
  position: relative;
  width: 100%;
}

.group2 {
  flex-shrink: 0;
  width: 82px;
  height: 82px;
  position: relative;
  transform: translate(0px, 0px);
  overflow: visible;
}

.paga-un-precio-justo {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  position: relative;
  width: 229px;
}

.paga-un-precio-justo-span {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h4-font-size, 30px);
  line-height: var(--headings-h4-line-height, 36px);
  font-weight: var(--headings-h4-font-weight, 400);
}

.paga-un-precio-justo-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Black", sans-serif;
  font-size: 26px;
  line-height: 28px;
  font-weight: 900;
}

.ten-la-misma-cobertura-de-siempre-pero-a-un-precio-conveniente-y-sin-pagar-de-m-s {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
  position: relative;
  width: 304px;
  height: 80px;
}

.frame-102 {
  background: #d9d9d9;
  border-radius: 20px;
  padding: 12px 10px 12px 10px;
  display: flex;
  flex-direction: row;
  gap: 21px;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 360px;
  height: 220px;
  position: relative;
}

.group3 {
  flex-shrink: 0;
  width: 82px;
  height: 82px;
  position: relative;
  transform: translate(0px, 0px);
  overflow: visible;
}

.ten-tu-cashback {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  position: relative;
  width: 229px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ten-tu-cashback-span {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h4-font-size, 30px);
  line-height: var(--headings-h4-line-height, 36px);
  font-weight: var(--headings-h4-font-weight, 400);
}

.ten-tu-cashback-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 24px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
}

.ten-tu-cashback-span3 {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Black", sans-serif;
  font-size: 26px;
  line-height: 28px;
  font-weight: 900;
}

.al-portarte-te-devolveremos-lo-que-pagaste-de-m-s-directo-a-tu-cuenta-bancaria {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
  position: relative;
  width: 300px;
  height: 80px;
}

.frame-112 {
  background: #d9d9d9;
  border-radius: 20px;
  padding: 12px 10px 12px 10px;
  display: flex;
  flex-direction: row;
  gap: 21px;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 360px;
  height: 220px;
  position: relative;
}

.group4 {
  flex-shrink: 0;
  width: 82px;
  height: 82px;
  position: relative;
  transform: translate(0px, 0px);
  overflow: visible;
}

.siempre-estar-s-protegido {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  position: relative;
  width: 260px;
}

.siempre-estar-s-protegido-span {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h4-font-size, 30px);
  line-height: var(--headings-h4-line-height, 36px);
  font-weight: var(--headings-h4-font-weight, 400);
}

.siempre-estar-s-protegido-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Black", sans-serif;
  font-size: 26px;
  line-height: 28px;
  font-weight: 900;
}

.nos-aseguramos-de-que-al-momento-de-realizar-tu-cambio-jam-s-te-quedes-sin-cobertura {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 18px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
  position: relative;
  /* width: 330px;
  height: 80px; */
}

.c-mo-funciona4 {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h2-font-size, 40px);
  line-height: var(--headings-h2-line-height, 60px);
  font-weight: var(--headings-h2-font-weight, 700);
  position: relative;
  /* width: 1582px;
  height: 125px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.group-12 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}

.calcula-tu-devoluci-n-simula-en-l-nea-con-los-datos-de-tu-cr-dito-actual-y-calcula-cu-nto-dinero-podr-as-ahorrar-o-recuperar {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  position: relative;
  width: 267px;
  height: 101px;
}

.calcula-tu-devoluci-n-simula-en-l-nea-con-los-datos-de-tu-cr-dito-actual-y-calcula-cu-nto-dinero-podr-as-ahorrar-o-recuperar-span {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Black", sans-serif;
  font-size: 20px;
  line-height: 28px;
  font-weight: 900;
}

.calcula-tu-devolucion-text {
  background: #e8f3ff;
  border-radius: 20px;
  border-style: solid;
  border-color: #e8f3ff;
  border-width: 0.5px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  /* width: 420px; */
  height: 160px;
  position: relative;
  overflow: hidden;
}

.ingresa-text {
  background: #e8f3ff;
  border-radius: 20px;
  border-style: solid;
  border-color: #e1ebf9;
  border-width: 0.5px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  /* width: 420px; */
  height: 160px;
  position: relative;
  overflow: hidden;
}

.group-11 {
  flex-shrink: 0;
  width: 20px;
  height: 18px;
  position: relative;
  overflow: visible;
}

.reg-strate-completa-el-registro-para-que-podamos-aprobar-tu-solicitud {
  text-align: left;
  position: relative;
  width: 260px;
}

.reg-strate-completa-el-registro-para-que-podamos-aprobar-tu-solicitud-span {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Black", sans-serif;
  font-size: 20px;
  line-height: 28px;
  font-weight: 900;
}

.reg-strate-completa-el-registro-para-que-podamos-aprobar-tu-solicitud-span2 {
  color: var(--azul-primario, #1d2eff);
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
}

.reg-strate-completa-el-registro-para-que-podamos-aprobar-tu-solicitud-span3 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--text-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--text-font-size, 16px);
  line-height: var(--text-line-height, 24px);
  font-weight: var(--text-font-weight, 400);
}

.adjunta-text {
  background: #e8f3ff;
  border-radius: 20px;
  border-style: solid;
  border-color: #e8f3ff;
  border-width: 0.5px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  /* width: 420px; */
  height: 160px;
  position: relative;
  overflow: hidden;
}

.group-30 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}

.adjunta-tus-documentos-adjunta-los-antecedentes-de-tu-cr-dito-y-seguro-vigente {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  position: relative;
  width: 260px;
  height: 144px;
}

.adjunta-tus-documentos-adjunta-los-antecedentes-de-tu-cr-dito-y-seguro-vigente-span {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Black", sans-serif;
  font-size: 20px;
  line-height: 28px;
  font-weight: 900;
}

.adjunta-tus-documentos-adjunta-los-antecedentes-de-tu-cr-dito-y-seguro-vigente-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h7-font-family, "CircularPro-Black", sans-serif);
  font-size: var(--headings-h7-font-size, 16px);
  line-height: var(--headings-h7-line-height, 24px);
  font-weight: var(--headings-h7-font-weight, 900);
}

.adjunta-tus-documentos-adjunta-los-antecedentes-de-tu-cr-dito-y-seguro-vigente-span3 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--text-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--text-font-size, 16px);
  line-height: var(--text-line-height, 24px);
  font-weight: var(--text-font-weight, 400);
}

.firma-text {
  background: #e8f3ff;
  border-radius: 20px;
  border-style: solid;
  border-color: #e1ebf9;
  border-width: 0.5px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  /* width: 420px; */
  height: 160px;
  position: relative;
  overflow: hidden;
}

.group-9 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}

.firma-haz-tu-firma-digital-en-el-mandato-y-o-carta-de-renuncia-para-autorizarnos-a-gestionar-tu-portabilidad {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  position: relative;
  width: 260px;
  height: 144px;
}

.firma-haz-tu-firma-digital-en-el-mandato-y-o-carta-de-renuncia-para-autorizarnos-a-gestionar-tu-portabilidad-span {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Black", sans-serif;
  font-size: 20px;
  line-height: 28px;
  font-weight: 900;
}

.firma-haz-tu-firma-digital-en-el-mandato-y-o-carta-de-renuncia-para-autorizarnos-a-gestionar-tu-portabilidad-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h7-font-family, "CircularPro-Black", sans-serif);
  font-size: var(--headings-h7-font-size, 16px);
  line-height: var(--headings-h7-line-height, 24px);
  font-weight: var(--headings-h7-font-weight, 900);
}

.firma-haz-tu-firma-digital-en-el-mandato-y-o-carta-de-renuncia-para-autorizarnos-a-gestionar-tu-portabilidad-span3 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--text-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--text-font-size, 16px);
  line-height: var(--text-line-height, 24px);
  font-weight: var(--text-font-weight, 400);
}

.beneficios-text {
  background: #e8f3ff;
  border-radius: 20px;
  border-style: solid;
  border-color: #e1ebf9;
  border-width: 0.5px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  /* width: 420px; */
  height: 170px;
  position: relative;
  overflow: hidden;
}

.group-8 {
  flex-shrink: 0;
  width: 20px;
  height: 21.43px;
  position: relative;
  overflow: visible;
}

.disfruta-tu-beneficio-tu-nuevo-seguro-se-paga-con-parte-de-la-devoluci-n-de-prima-no-consumida-y-recuperas-directo-a-tu-cuenta-el-saldo-a-favor {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  position: relative;
  width: 260px;
  height: 144px;
}

.disfruta-tu-beneficio-tu-nuevo-seguro-se-paga-con-parte-de-la-devoluci-n-de-prima-no-consumida-y-recuperas-directo-a-tu-cuenta-el-saldo-a-favor-span {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Black", sans-serif;
  font-size: 20px;
  line-height: 28px;
  font-weight: 900;
}

.disfruta-tu-beneficio-tu-nuevo-seguro-se-paga-con-parte-de-la-devoluci-n-de-prima-no-consumida-y-recuperas-directo-a-tu-cuenta-el-saldo-a-favor-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h7-font-family, "CircularPro-Black", sans-serif);
  font-size: var(--headings-h7-font-size, 16px);
  line-height: var(--headings-h7-line-height, 24px);
  font-weight: var(--headings-h7-font-weight, 900);
}

.disfruta-tu-beneficio-tu-nuevo-seguro-se-paga-con-parte-de-la-devoluci-n-de-prima-no-consumida-y-recuperas-directo-a-tu-cuenta-el-saldo-a-favor-span3 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--text-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--text-font-size, 16px);
  line-height: var(--text-line-height, 24px);
  font-weight: var(--text-font-weight, 400);
}

.vector {
  flex-shrink: 0;
  width: 120px;
  height: 120px;
  /* position: absolute; */
  /* left: 1620px;
  top: 1946px; */
  margin-left: -302px;
  margin-top: 486px;
  overflow: visible;
}

.carrusel2 {
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  height: 650px;
  /* position: absolute; */
  left: 864px;
  top: calc(50% - 290.5px);
  overflow: hidden;
  margin-top: 100px;
background-color: black;
}

.frame-6 {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  height: 681px;
  position: relative;
}

.person-working-on-a-laptop-in-a-cozy-home-office-2024-03-01-16-35-10-utc-1 {
  border-radius: 40px;
  flex-shrink: 0;
  width: 918px;
  height: 681px;
  position: relative;
  object-fit: cover;
}

.vector2 {
  flex-shrink: 0;
  width: 1395.63px;
  height: 1395.63px;
  position: absolute;
  left: -643.18px;
  top: 1261.88px;
  transform: translate(643.18px, -1261.88px);
  overflow: visible;
}

.couple-using-tablet-computer-2023-11-27-05-32-54-utc-1 {
  border-radius: 40px;
  flex-shrink: 0;
  width: 918px;
  height: 612px;
  position: relative;
  object-fit: cover;
}

.he-gets-the-team-up-and-running-2023-11-27-04-56-45-utc-1 {
  border-radius: 40px;
  flex-shrink: 0;
  width: 918px;
  height: 612px;
  position: relative;
  object-fit: cover;
}

.young-boss-hiring-new-colleague-shaking-hands-afte-2023-11-27-05-25-00-utc-1 {
  border-radius: 40px;
  flex-shrink: 0;
  width: 918px;
  height: 612px;
  position: relative;
  object-fit: cover;
}

.rectangle-27 {
  background: var(--blanco, #f0f2f2);
  border-radius: 93px;
  flex-shrink: 0;
  width: 262px;
  height: 31px;
  position: absolute;
  left: 41px;
  top: 16px;
  border: 1px solid darkgrey;
  cursor: pointer;
}

.ingresa {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
  position: absolute;
  left: -8px;
  top: 16px;
  width: 361px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.es-posible-la-portabilidad-del-seguro-de-desgravamen-claro-que-s-mueve-seguro-actua-conforme-a-normas-y-leyes-vigentes-span {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Bold", sans-serif;
  font-size: 45px;
  line-height: 60px;
  font-weight: 700;
}

.es-posible-la-portabilidad-del-seguro-de-desgravamen-claro-que-s-mueve-seguro-actua-conforme-a-normas-y-leyes-vigentes-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Book", sans-serif;
  font-size: 31px;
  line-height: 36px;
  font-weight: 400;
  margin-top: -15px;
}

.ley-1 {
  border-radius: 20px;
  border-style: solid;
  border-color: var(--azul-complementario, #04137f);
  border-width: 1px;
  flex-shrink: 0;
  width: 352px;
  height: 320px;
  position: relative;
}

.crop-ethnic-businessman-signing-papers-2023-11-27-05-18-25-utc {
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  height: 62.5%;
  position: absolute;
  right: 0%;
  left: 0%;
  bottom: 37.5%;
  top: 0%;
  object-fit: cover;
}

.rectangle-23 {
  background: var(--azul-claro, #e8f3ff);
  border-radius: 0px 0px 20px 20px;
  width: 100%;
  height: 37.5%;
  position: absolute;
  right: 0%;
  left: 0%;
  bottom: 0%;
  top: 62.5%;
}

.circular-2114-26-07-2013-cmf {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h4-font-size, 27px);
  line-height: var(--headings-h4-line-height, 36px);
  font-weight: var(--headings-h4-font-weight, 400);
  position: absolute;
  right: 10%;
  left: 4.75%;
  width: 85.25%;
  bottom: 0%;
  top: 62.5%;
  height: 37.5%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ley-2 {
  border-radius: 20px;
  border-style: solid;
  border-color: var(--azul-complementario, #04137f);
  border-width: 1px;
  flex-shrink: 0;
  width: 352px;
  height: 320px;
  position: relative;
}

.asian-woman-designer-working-with-laptop-in-creati-2023-11-27-05-04-40-utc {
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  height: 62.5%;
  position: absolute;
  right: 0%;
  left: 0%;
  bottom: 37.5%;
  top: 0%;
  object-fit: cover;
}

.rectangle-24 {
  background: var(--azul-claro, #e8f3ff);
  border-radius: 0px 0px 20px 20px;
  width: 100%;
  height: 37.5%;
  position: absolute;
  right: 0%;
  left: 0%;
  bottom: 0%;
  top: 62.5%;
}

.ley-19-496-art-17-d {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h4-font-size, 27px);
  line-height: var(--headings-h4-line-height, 36px);
  font-weight: var(--headings-h4-font-weight, 400);
  position: absolute;
  right: 7.25%;
  left: 7.5%;
  width: 85.25%;
  bottom: 0%;
  top: 62.5%;
  height: 37.5%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ley-3 {
  border-radius: 20px;
  border-style: solid;
  border-color: var(--azul-complementario, #04137f);
  border-width: 1px;
  flex-shrink: 0;
  width: 352px;
  height: 320px;
  position: relative;
}

.female-advisor-attorney-lawyer-consulting-client-i-2024-02-02-18-20-30-utc {
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  height: 62.5%;
  position: absolute;
  right: 0%;
  left: 0%;
  bottom: 37.5%;
  top: 0%;
  object-fit: cover;
}

.rectangle-25 {
  background: var(--azul-claro, #e8f3ff);
  border-radius: 0px 0px 20px 20px;
  width: 100%;
  height: 37.5%;
  position: absolute;
  right: 0%;
  left: 0%;
  bottom: 0%;
  top: 62.5%;
}

.ley-20-448-art-8 {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h4-font-size, 27px);
  line-height: var(--headings-h4-line-height, 36px);
  font-weight: var(--headings-h4-font-weight, 400);
  position: absolute;
  right: 3.5%;
  left: 11.25%;
  width: 85.25%;
  bottom: 0%;
  top: 62.5%;
  height: 37.5%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.vector3 {
  flex-shrink: 0;
  width: 120px;
  height: 120px;
  /* position: absolute;
  left: 1860px;
  top: 322px; */
  /* overflow: visible; */
  margin-top: 296px;
  margin-right: -130px;
  border-radius: 104px;
}



.vector4 {
  margin-left: -140px;
  margin-top: 100px;
  flex-shrink: 0;
  width: 198px;
  height: 198px;
  /* position: absolute;
  left: -67px;
  top: 70px; */
  /* overflow: visible; */
  border-radius: 104px;
}


.frame-23 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 100%;
  position: relative;
}

.requisitos-de-portabilidad {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h2-font-size, 48px);
  line-height: var(--headings-h2-line-height, 60px);
  font-weight: var(--headings-h2-font-weight, 700);
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.group-16 {
  flex-shrink: 0;
  width: 270px;
  height: 270px;
  position: static;
}

.rectangle-30 {

  background: #e8f3ff;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  /* position: absolute; */
  right: 72.04%;
  left: 0%;
  bottom: 0%;
  top: 0%;
  display: flex;
  justify-content: center;
}

.vector5 {
  width: 7.2%;
  height: 33.5%;
  position: absolute;
  right: 82.47%;
  left: 10.32%;
  bottom: 57%;
  top: 9.5%;
  overflow: visible;
}

._1 {
  color: var(--blanco, #f0f2f2);
  text-align: center;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h2-font-size, 48px);
  line-height: var(--headings-h2-line-height, 60px);
  font-weight: var(--headings-h2-font-weight, 700);
  position: absolute;
  right: 85.16%;
  left: 13.01%;
  width: 1.83%;
  bottom: 62%;
  top: 14.5%;
  height: 23.5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tener-un-cr-dito-vigente-con-seguro-de-degravamen-en-bancos-cooperativas-o-automotoras {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
  /* position: absolute; */
  right: 72.9%;
  left: 0.86%;
  width: 244px;
  bottom: 11.5%;
  top: 48.5%;
  height: 80px;
}

.excluye-cr-ditos-hipotecarios2 {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
  position: relative;
  width: 100%;
}

.frame-22 {
  padding: 20px 0px 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 100%;
  position: relative;
}

.historias-de-xito {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h2-font-size, 48px);
  line-height: var(--headings-h2-line-height, 60px);
  font-weight: var(--headings-h2-font-weight, 700);
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rectangle-28 {
  background: var(--azul-complementario, #04137f);
  border-radius: 20px;
  width: 100%;
  height: 100%;
  position: relative;
  padding-bottom: 20px;
}

.texto_historia {
  color: var(--blanco, #f0f2f2);
  text-align: left;
  font-family: var(--text-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--text-font-size, 16px);
  line-height: var(--text-line-height, 24px);
  font-weight: var(--text-font-weight, 400);
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 20px 0px 20px;

}

.nombre_historia {
  color: var(--blanco, #f0f2f2);
  text-align: left;
  font-family: var(--headings-h7-font-family, "CircularPro-Black", sans-serif);
  font-size: var(--headings-h7-font-size, 16px);
  line-height: var(--headings-h7-line-height, 24px);
  font-weight: var(--headings-h7-font-weight, 900);
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.compa-as-que-nos-respaldan {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h2-font-size, 48px);
  line-height: var(--headings-h2-line-height, 60px);
  font-weight: var(--headings-h2-font-weight, 700);
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.frame-41 {
  width: 100%;
  height: 64px;
  position: relative;
  display: flex;
  justify-content: center;
  /* left: 48px;
  top: 3px; */
}

.frame-292 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.group-40 {
  flex-shrink: 0;
  width: 360px;
  height: 75.25px;
  position: static;
}

.group-39 {
  width: 360px;
  height: 75.25px;
  position: static;
}

.una-empresa-de {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
  /* position: absolute; */
  /* left: 0px;
  /* top: 11.12px;  */
  width: 100%;
  /* height: 53px; */
  display: flex;
  align-items: center;
  justify-content: center;

}

.logotipo-grupo-insurex-blanco-1 {
  width: 150px;
  width: 150px;
  height: 60.25px;
  align-items: center;
  justify-content: center;
  /* height: 75.25px; */
  /* position: absolute; */
  /* left: 160px; */
  /* top: 0px; */
  /* object-fit: cover; */
}

.frame-39 {
  display: flex;
  /* flex-direction: row; */
  gap: 11px;
  /* align-items: flex-end; */
  justify-content: center;
  /* flex-wrap: wrap; */
  width: 100%;
  height: 100%;
  position: relative;
  /* left: 48px;
  top: 97px; */
}

.group-392 {
  flex-shrink: 0;
  width: 100%;
  height: 48px;
  /* position: static; */
  display: flex;
  justify-content: center;
}

.regulados-por {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
  position: relative;
  /* left: 0px; */
  /* top: -11px; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dise-o-sin-t-tulo-27-1 {
  width: 69px;
  height: 48px;
  position: relative;
  /* left: 156px; */
  top: -6px;
  object-fit: cover;
}

.dise-o-sin-t-tulo-29-1 {
  width: 116px;
  height: 24px;
  position: relative;
  /* left: 233px; */
  top: 6px;
  object-fit: cover;
}

.mueve-seguro-ingresa-registrate {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  position: relative;
  width: 87%;
  height: 150px;
}

.mueve-seguro-ingresa-registrate-span {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Black", sans-serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: 900;
}

.mueve-seguro-ingresa-registrate-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 24px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
}

.mueve-seguro-ingresa-registrate-span3 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
}

.seguro-cotiza-beneficios-qu-es {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  position: relative;
  width: 87%;
  height: 150px;
}

.seguro-cotiza-beneficios-qu-es-span {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Black", sans-serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: 900;
}

.seguro-cotiza-beneficios-qu-es-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 24px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
}

.seguro-cotiza-beneficios-qu-es-span3 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
}

.contacto-comun-cate-con-nosotros-en-servicioalcliente-mueveseguro-cl {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  position: absolute;
  /* left: 1084px;
  top: 26px; */
  width: 308px;
  height: 180px;
}

.contacto-comun-cate-con-nosotros-en-servicioalcliente-mueveseguro-cl-span {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Black", sans-serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: 900;
}

.contacto-comun-cate-con-nosotros-en-servicioalcliente-mueveseguro-cl-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
}

.group-34 {
  flex-shrink: 0;
  width: 308px;
  height: 180px;
  position: static;
  text-align: center;
}

.group-32 {
  width: 308px;
  height: 120px;
  position: static;
  margin-bottom: 20px;
}

.group-33 {
  width: 211.8px;
  height: 43.8px;
  position: relative;
  /* left: 1133px;
  top: 142px; */
  overflow: visible;
}

.sobre-nosotros-somos-misi-n-visi-n-valores {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  position: relative;
  width: 87%;
  height: 180px;
}

.sobre-nosotros-somos-misi-n-visi-n-valores-span {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Black", sans-serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: 900;
  cursor: pointer;
}

.sobre-nosotros-somos-misi-n-visi-n-valores-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 18px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
  text-align: justify;
  cursor: pointer;
}

.btn-contrata {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.navapp {
  box-shadow: none !important;
}

.home-logotipo-mueve-seguroconbajada3 {
  width: 325px;
}

.avrow3 {
  margin-top: 30px;
}

.bannerIz {
  padding: 60px;
}

.bannerCn {
  /* padding: 80px; */
  display: flex;
  justify-content: center;
  padding-top: 0px !important;
}

.bannerDr {
  padding: 60px;
}

.row3 {
  background: var(--blanco, #f0f2f2);
  display: flex !important;
  justify-content: center !important;
}

@media only screen and (max-width: 800px) {
  .la-portabilidad-llega-al-mundo-de-los-seguros-de-desgravamen {
    color: var(--blanco, #f0f2f2);
    text-align: left;
    font-family: var(--headings-h1-font-family, "CircularPro-Bold", sans-serif);
    font-size: 30px;
    line-height: 50px;
    font-weight: 900;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .quieres-portar-tu-seguro-de-desgravamen {
  color: #ffffff;
  text-align: center;
  font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h2-font-size, 20px);
  line-height: var(--headings-h2-line-height, 30px);
  font-weight: var(--headings-h2-font-weight, 400);
  position: relative;
  /* width: 560px; */
  display: flex;
  align-items: center;
  justify-content: center;
}
  /* .la-portabilidad-llega-al-mundo-de-los-seguros-de-desgravamen {
  color: var(--blanco, #f0f2f2);
  text-align: left;
  font-family: var(--headings-h1-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h1-font-size, 64px);
  line-height: var(--headings-h1-line-height, 72px);
  font-weight: var(--headings-h1-font-weight, 700);
  position: relative;
  width: 800px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
} */

  .bannerIz {
    /* padding: 50px; */
  }

  .bannerCn {
    /* padding: 80px; */
    display: flex;
    justify-content: center;
    padding-top: 60px;
  }

  .piggy-1 {
    flex-shrink: 0;
    width: 100%;
    /* height: 388px; */
    /* position: relative; */
    object-fit: cover;
    align-content: center;
    margin-top: 0px !important;

  }



  .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin: 0px !important;

  }
}

.menu-t {
  display: unset !important;
}

.menu-m {
  display: none !important;
}

.mdi-menu {
  margin-top: 45px !important;
  font-size: 53px !important;
  color: rgba(4, 19, 127, 1) !important;
}

.size-video{
  width : 800px ;
  margin-top: 152px;
}
@media only screen and (max-width: 800px) {
  .size-video{
      width : 300px !important ;  
      margin-top: 70px; 
  }
  .vector{
    margin-top: 0;
  }
}
@media only screen and (max-width: 1000px) {
  .size-video{
      width : 350px !important ;   
      margin-top: 80px;
  }
}
@media only screen and (max-width: 1250px) {
  .size-video{
      width : 380px ;   
      margin-top: 90px;
  }
}
@media only screen and (max-width: 1380px) {
  .la-portabilidad-llega-al-mundo-de-los-seguros-de-desgravamen {
    color: var(--blanco, #f0f2f2);
    text-align: left;
    font-family: var(--headings-h1-font-family, "CircularPro-Bold", sans-serif);
    font-size: 35px;
    line-height: 50px;
    font-weight: 900;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: flex-start;

  }
  .size-video{
    width : 530px  ;    
    margin-top: 100px;
  }
  .carrusel2{
    height: 400px;
  }

  .bannerlogo {
    background-image: url('/assets/teaser-ms-02-10.png');
    background-repeat: no-repeat;
    background-size: auto 100%;
    margin-left: 0px !important;
  }

  /* .la-portabilidad-llega-al-mundo-de-los-seguros-de-desgravamen {
  color: var(--blanco, #f0f2f2);
  text-align: left;
  font-family: var(--headings-h1-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h1-font-size, 64px);
  line-height: var(--headings-h1-line-height, 72px);
  font-weight: var(--headings-h1-font-weight, 700);
  position: relative;
  width: 800px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
} */

  .bannerIz {
    padding: 20px;
    padding-top: 50px;
  }

  .menu-t {
    display: none !important;
  }

  .menu-m {
    display: unset !important;
  }
/*
  .rotateimage {
    display: none !important;
  }
*/

  .circuloiz {
    display: none !important;
  }

  .circulodr {
    display: none !important;
  }

}

.colcenter {
  display: flex;
  justify-content: center;
}

.rowpage2 {
  background-color: #f0f2f2;
}

.pdrotate {
  padding: 20px;
}

.rotate-container {
  align-self: self-start;
}

.colcenter {}
</style>