<template>
  <v-app class="tmpbase">
    <v-row class="bannerlogo rowpage2" :class="{'bannerlogo2' : estadoMenuPrivado=='14','bannerlogo3' : estadoMenuPrivado=='2'}" style="display: flex; justify-content: center; ">
      <Nav class="nastyle" :titulo="`¡Hola ` + loginUsuario.acc_nombre_principal + ` ` + loginUsuario.acc_ape_paterno + `!`"
        :fondo="false" :valores="true" :ufActual="ufActual" :fechaAcceso="fechaAcceso" :menuPrivadoCab="true"></Nav>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12"></v-col>
      <v-row class="colcenter">


        <v-col cols="12" sm="12" md="11" lg="8" xl="8" class="colcenter menuprivado">
          <v-bottom-navigation  :value="valueMenu" horizontal class="menuprivado">
            <v-btn  class="btnMenu" @click="cambiaMenu(1)">
              <img class="imageBtnIni" src="/assets/privado/group-440.svg">
            </v-btn>
            <v-divider vertical></v-divider>
            <v-btn class="btnMenu" @click="cambiaMenu(4)">
              <div class="frame-44">
                <div><img class="imageBtn" src="/assets/privado/vector1.svg"></div>
                <div class="contratar-seguro">Contratar seguro</div>
              </div>
            </v-btn>
            <v-divider vertical></v-divider>
            <v-btn class="btnMenu" @click="cambiaMenu(3)">
              <div class="frame-44">
                <div><img class="imageBtn" src="/assets/privado/vector2.svg"></div>
                <div class="contratar-seguro" >Mis simulaciones</div>
              </div>
            </v-btn>
            <v-divider vertical></v-divider>
            <v-btn class="btnMenu" @click="cambiaMenu(2)">
              <div class="frame-44">
                <div><img class="imageBtn" src="/assets/privado/vector3.svg"></div>
                <div class="contratar-seguro">Mis seguros</div>
              </div>

            </v-btn>
            <v-divider vertical></v-divider>
            <v-btn class="btnMenu" @click="cambiaMenu(16)">
              <div class="frame-44">
                <div><img class="imageBtn" src="/assets/privado/union0.svg"></div>
                <div class="contratar-seguro">Mi dinero</div>
              </div>

            </v-btn>
            <v-divider vertical></v-divider>
            <v-btn class="btnMenu">
              <div class="frame-44">
                <div><img class="imageBtn" src="/assets/privado/vector4.svg"></div>
                <div class="contratar-seguro">Declaración de siniestros</div>
              </div>

            </v-btn>
          </v-bottom-navigation>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12"></v-col>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12"></v-col>
       
        <Inicio
            v-if="estadoMenuPrivado == 1"
            :montoSaldos="montoSaldos"
        ></Inicio>
        <CotizacionCab
            v-if="estadoMenuPrivado == 3"
        ></CotizacionCab>
        <MiCuentaCab
            v-if="estadoMenuPrivado == 10"
        ></MiCuentaCab>
        <MisSegurosMo
            v-if="estadoMenuPrivado == 2"
        ></MisSegurosMo>
        <WalletCab
            v-if="estadoMenuPrivado == 16"
        ></WalletCab>
      
        <v-col class="mb-10" v-if="estadoMenuPrivado == 14 || estadoMenuPrivado == 15 || estadoMenuPrivado == 10" cols="12" sm="12" md="12" lg="12" xl="12"></v-col>
        <v-col class="mb-10" v-if="estadoMenuPrivado == 14 || estadoMenuPrivado == 15 " cols="12" sm="12" md="12" lg="12" xl="12"></v-col>

      </v-row>
    </v-row>
    <MiCuenta 
      v-if="estadoMenuPrivado == 10">
    </MiCuenta>
    <SimulacionPago :Seguro="seguro" :pagado="pagado"
    v-if="estadoMenuPrivado == 14"
    ></SimulacionPago>
    <Pagar :Seguro="seguro"
    v-if="estadoMenuPrivado == 15"
    ></Pagar>
    <PaginaPrivado
    v-if="estadoMenuPrivado == 1 || estadoMenuPrivado == 2"
    ></PaginaPrivado>
    <Cotizacion
    v-if="estadoMenuPrivado == 3"
    >      
    </Cotizacion>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      
      <v-card class="fondoDialog">
        <v-card-title class="lighten-2 headDialog">
          <v-row>
          <v-col cols="8" sm="8" md="8" lg="8" xl="8" style="align-self: center;">
            <span class="headDialog">Documento firmado</span>
          </v-col>
          <v-col cols="4" sm="4" md="4" lg="4" xl="4">
            <v-icon large color="success" style="font-size: 40px">
              mdi-check-circle
          </v-icon>
          </v-col>
        </v-row>
        </v-card-title>

        <v-card-text>
          <br>
          <span class="dialogFirma2">
          Presione Mis cotizaciones y luego boton verde Continuar
          para que su simulación pase a Revisión del Ejecutivo
        </span>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="juanito"
            rounded
            large
            @click="dialog = false"
            style="color:white"
          >
            Mis cotizaciones
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog2"
      persistent
      width="500"
    >
      <v-card class="fondoDialog"
        color=""
      >
      <v-card-title class="lighten-2 headDialog">
        <v-row>
          <v-col cols="8" sm="8" md="8" lg="8" xl="8" style="align-self: center;">
            <span class="headDialog">PROCESANDO</span>
          </v-col>
          <v-col cols="4" sm="4" md="4" lg="4" xl="4">
            <v-icon large color="azulsitio" style="font-size: 40px">
                mdi-clock
          </v-icon>
          </v-col>
        </v-row>
           
         
        </v-card-title>
        <v-card-text>
          <br>
          <span class="dialogFirma2">
          En estos momentos estamos preparando el documento para la firma electrónica
        </span>
        </v-card-text>
         <v-card-actions class="colcenter">
          <div class="mb-3">
            <v-progress-circular
              :rotate="-90"
              :size="100"
              :width="15"
              :value="value"
              color="azulsitio"
            >
              {{ value }}%
            </v-progress-circular>
            <br>
        </div>
        
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Wallet 
      v-if="estadoMenuPrivado == 16">
    </Wallet>
    <Footer></Footer>
    <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>

</template>
<script>
import privado from "../../../services/privado";
import datausuario from "../../../services/usuario-datos";

import Nav from "../components/NavPrivado.vue";
// import homedata from "../../../services/home";
// import Inicio from "./components/Inicio.vue";
// import Seguros from "./components/Seguros.vue";
import Cotizacion from "./components/CotizacionMo.vue";
import CotizacionCab from "./components/CotizacionCabMo.vue";
import MiCuentaCab from "./components/MiCuentaCabMo.vue";
import SimulacionPago from "./components/SimulacionPagoMo.vue";
import Pagar from "./components/PagarMo.vue";
import MiCuenta from "./components/MiCuentaMo.vue";
import WalletCab from "./components/WalletCabMo.vue";
import Wallet from "./components/WalletMo.vue";
// import MiCuenta from "./components/MiCuenta.vue";
// import Endoso from "./components/Endoso.vue";
// import Siniestro from "./components/Siniestro.vue";
// import Wallet from "./components/Wallet.vue";

import Footer from "./components/Footer.vue";
import Inicio from "./components/InicioMo.vue";
import MisSegurosMo from "./components/MisSegurosMo.vue";
import PaginaPrivado from "./components/PaginaPrivadoMo.vue";
import { mdiTruckRemoveOutline } from "@mdi/js";

const PUBLIC_DOWNLOAD = process.env.VUE_APP_PUBLIC_DOWNLOAD;

export default {
  name: "HomePrivado",
  components: {
    Inicio: Inicio,
    // Seguros: Seguros,
    Cotizacion: Cotizacion,
    MisSegurosMo: MisSegurosMo,
    CotizacionCab: CotizacionCab,
    MiCuentaCab: MiCuentaCab,
    SimulacionPago: SimulacionPago,
    Pagar: Pagar,
    MiCuenta: MiCuenta,
    // MiCuenta: MiCuenta,
    // Endoso: Endoso,
    // Siniestro: Siniestro,
    WalletCab: WalletCab,
    Wallet: Wallet,
    Nav: Nav,
    Footer: Footer,
    PaginaPrivado: PaginaPrivado
  },
  data() {
    return {
      seguro: {},
      valueMenu: "",
      interval: {},
      value: 0,
      dialog : false,
      dialog2 : false,
      dialogElimina: false,
      seguroElimina: false,
      fechaAcceso: "",
      ufActual: "",
      cierraMenu: false,
      menuCab: false,
      selectMenu: this.$store.state.selectMenu,
      misSeguros: [],
      misCotizaciones: [],
      estadoMenuPrivado: 1,
      montoSaldos: this.$store.state.montoSaldos,
      datosContrato: {},
      loginEstado: "",
      loginUsuario: {},
      nombrelogin: "",
      rutEmpresa: "",
      overlay: false,
      isLogin: this.$store.state.islogin,
      cliente: this.$store.state.cliente,
      pagado: 0,
      admins: [
        ['Mis datos personales', 'mdi-circle',10],
        ['Cambia a perfil empresa', 'mdi-circle',11],
      ],
    };
  },
  methods: {
    irHome(){
      this.$router.push("/home");
    },
    async cambiaMenu(menu) {
   
      this.$store.commit("setselectmenu",menu);
      this.overlay = true;
      var res = false;
      if (menu == 2) {
       
        res = await privado.traeSeguros(this.loginUsuario.contrato.ctt_numero);
        if (res.status == 200 && res.data.estadoTransaccion.success) {
          this.misSeguros = [];
          var ini = "";
          var ter = "";
          res.data.contratoSeguro.forEach((element) => {
            
            this.misSeguros.push({
              cse_nombre_compania: element.cse_nombre_compania,
              cse_forma_pago: element.cse_forma_pago,
              cse_pln_glosa: element.cse_pln_glosa,
              cse_pln_poliza_individual: element.cse_pln_poliza_individual,
              cse_sim_monto_credito: element.cse_sim_monto_credito,
              cse_sim_saldo_insoluto: element.cse_sim_saldo_insoluto,
              cse_spl_seg_numero: element.cse_spl_seg_numero,
              cse_nombre_compania: element.cse_nombre_compania,
              cse_pln_fecha_inicio: element.cse_pln_fecha_inicio,
              cse_pln_fecha_termino: element.cse_pln_fecha_termino,
              cse_estado: element.cse_estado,
              cse_sim_monto_prima_cl: element.cse_sim_monto_prima_cl,
              spl_numero: element.spl_numero,
              cse_institucion: element.cse_institucion,
              cse_nro_credito_original:element.cse_nro_credito_original,
              activar: false,
              
            });
            
          });
          if(this.estadoMenuPrivado==2){
            this.$root.$refs.PrivadoSeguros.actualizaDatos();
          }
          this.overlay = false;
        }else{
            this.misSeguros = [];
            if(this.estadoMenuPrivado==2){
            this.$root.$refs.PrivadoSeguros.actualizaDatos();
          }
            this.$alertas(res.data.estadoTransaccion.errors,"info");
            this.overlay = false;
        }
      }
      if (menu == 3) {
        res = await privado.traeCotizacionesRut(
            this.rutEmpresa
        );
        if (res.status == 200 && res.data.estadoTransaccion.success) {
          this.misCotizaciones = [];
          
          res.data.cotizacionesPlan.forEach((element) => {
            
            this.misCotizaciones.push({
              seleccion: false,
              numero: element.seg_numero,
              seg_simulacion_numero: element.seg_simulacion_numero,
              spl_pln_numero: element.spl_pln_numero,
              splestado: element.spl_estado_glosa,
              segestado: element.seg_estado_glosa,
              plan: element.seg_producto_glosa,
              prima: element.spl_sim_monto_prima_cl,
              monto: element.seg_usu_saldo_insoluto,
              nombre: element.seg_glosa_institucion,
              numero_plan: element.spl_numero,
              estado: element.spl_estado,
              cdo_ci_frente_estado_carga: element.cdo_ci_frente_estado_carga,
              cdo_mandato_estado_carga:element.cdo_mandato_estado_carga,
              cdo_renuncia_estado_carga: element.cdo_renuncia_estado_carga,
              cdo_prepago_estado_carga: element.cdo_prepago_estado_carga,
              cdo_resumen_credito_estado_carga : element.cdo_resumen_credito_estado_carga,
              estado_seguro: element.seg_estado,
              seg_usu_credito_nuevo: element.seg_usu_credito_nuevo,
              spl_pln_numero: element.spl_pln_numero,
              spl_estado_cotizacion : element.spl_estado_cotizacion,
              spl_estado_fea : element.spl_estado_fea,
              seg_sim_devolucion_calculada: element.seg_sim_devolucion_calculada,
              seg_sim_valor_prima_nuevo: element.seg_sim_valor_prima_nuevo,
              seg_sim_costo_operativo: element.seg_sim_costo_operativo,
              seg_valor_prima_costo_operativo: element.seg_valor_prima_costo_operativo,
              seg_sim_saldo_final_cliente: element.seg_sim_saldo_final_cliente,       
              cdo_estado_firma: element.cdo_estado_firma      
            });
          });
          if(this.estadoMenuPrivado==3){
            this.$root.$refs.PrivadoCotizacion.actualizaDatos();
          }
          this.overlay = false;
        }else{
            this.misCotizaciones = [];
            if(this.estadoMenuPrivado==3){
            this.$root.$refs.PrivadoCotizacion.actualizaDatos();
          }
            this.$alertas(res.data.estadoTransaccion.errors,"info");
            this.overlay = false;
        }

        
      }
      if (menu == 4) {
        this.$store.commit("setestadoseguro", 0);
        this.$store.commit("setselectmenu", 0);
        this.$store.commit("settipocotizacion", "vigente");
        this.$store.commit("settipopersonaempresa", "persona");
        this.$store.commit("setestadoFormCotizacionPrivado", 3);
        this.$router.push("/formulario-cotizacion");
      }
      if (menu == 10) {
        this.$store.commit("setselectmenu", 0);
      }
      if (menu == 11) {
        this.$store.commit("setselectmenu", 0);
        this.$router.push("/login2");
      }
      if (menu == 12) {
        this.$store.commit("setselectmenu", 0);
      }
      if (menu == 13) {
        this.$store.commit("setselectmenu", 0);
        this.$store.commit("setestadoFormCotizacionPrivado", 18);
        this.$router.push("/formulario-cotizacion");
      }
      this.cierraMenu = false;
      this.estadoMenuPrivado = menu;
      
      this.overlay = false;
      console.log(this.estadoMenuPrivado);
    },
    formatNumberES(n, d = 0) {
      n = new Intl.NumberFormat("es-ES").format(parseFloat(n).toFixed(d));
      if (d > 0) {
        // Obtenemos la cantidad de decimales que tiene el numero
        const decimals =
          n.indexOf(",") > -1 ? n.length - 1 - n.indexOf(",") : 0;

        // añadimos los ceros necesios al numero
        n =
          decimals == 0
            ? n + "," + "0".repeat(d)
            : n + "0".repeat(d - decimals);
      }
      return n;
    },
    async traeSaldos() {
      this.overlay=true;
      let res = false;
      this.datosContrato = JSON.parse(localStorage.getItem("datosContrato"));
      res = await privado.traeSaldos(this.loginUsuario.contrato.ctt_numero);
      if (res.status == 200 && res.data.estadoTransaccion.success) {
        this.$store.commit("setmontosaldos", res.data.montoSaldos);
        this.montoSaldos = res.data.montoSaldos;
      }
      this.overlay=false;
    },
    hover: function (name) {
      var el = document.querySelector("." + name);
      el.classList.add("selectMenu");
    },
    hoverout: function (name) {
      var el = document.querySelector("." + name);
      el.classList.remove("selectMenu");
    },
    cerrar: function () {
      this.$store.commit("setislogin", false);
      localStorage.removeItem("loginEstado");
      localStorage.removeItem("loginCliente");
      this.$router.push("/home");
    },
    async  cancelarTransaccion(tId){
      this.overlay=true;
      let res = false;
      const data = {
        tId: tId
      }
      res = await privado.cancelarTransaccion2(data);
      if (res.status == 200 && res.data.success) {
            this.cambiaMenu(14);
            this.pagado=2;
            this.overlay=false;
      }else{
           this.$alertas(res.data.errors, "warning");
           this.cambiaMenu(3);
           this.overlay=false;
      }
      
    },
    async  confirmarTransaccion2(tId){
      this.overlay=true;
      let res = false;
      const data = {
        tId: tId
      }
      res = await privado.confirmarTransaccion2(data);
      if (res.status == 200 && res.data.estadoTransaccion.success) {
        if(res.data.pag_estado==2){
          
          this.seguro.numero = res.data.miSeguro.cse_numero;
          this.seguro.poliza = res.data.miSeguro.cse_poliza;
          this.seguro.transaccion = res.data.miSeguro.cse_transaccion;
          this.seguro.spl_pln_fecha_inicio = res.data.miSeguro.cse_pln_fecha_inicio;
          this.seguro.spl_pln_fecha_termino = res.data.miSeguro.cse_pln_fecha_termino;
          this.seguro.celular = res.data.miSeguro.cse_celular;
          this.seguro.email = res.data.miSeguro.cse_email;
          this.seguro.direccion = res.data.miSeguro.cse_calle + " #" +
              res.data.miSeguro.cse_nro + ", " + res.data.miSeguro.cse_depto + ", " +
              res.data.miSeguro.cse_comuna + " " + res.data.miSeguro.cse_ciudad;
          this.seguro.nombre = res.data.miSeguro.cse_nombre;
          this.seguro.rut = res.data.miSeguro.cse_rut;
          this.seguro.spl_sim_monto_prima_cl = res.data.miSeguro.cse_sim_monto_prima_cl;
          this.seguro.spl_sim_monto_prima_uf = res.data.miSeguro.cse_sim_monto_prima_uf;
          this.seguro.spl_sim_saldo_insoluto = res.data.miSeguro.cse_sim_monto_credito;
          this.seguro.tipoInstitucion = res.data.miSeguro.cse_glosa_tipo_institucion;
          this.seguro.nombreIntitucion = res.data.miSeguro.cse_glosa_institucion;
          this.seguro.saldoInsoluto = res.data.miSeguro.cse_sim_monto_credito;
          this.seguro.mesesPendientePago = res.data.miSeguro.cse_meses_pendiente;
          this.seguro.periodoGracia = res.data.miSeguro.cse_periodo_gracia;
          this.seguro.plazoCredito = res.data.miSeguro.cse_plazo_meses;
          this.seguro.cantidadCuotas = res.data.miSeguro.cse_meses_pendiente;
          this.seguro.spl_numero = res.data.miSeguro.spl_numero;
          this.seguro.spl_seguro_numero = res.data.miSeguro.spl_seguro_numero;
          this.seguro.cse_pln_numero = res.data.miSeguro.cse_pln_numero;
          this.seguro.cse_numero = res.data.miSeguro.cse_numero;
          this.seguro.cse_ctt_numero = res.data.miSeguro.cse_ctt_numero;
          this.seguro.cse_seg_numero = res.data.miSeguro.cse_seg_numero;
          this.seguro.cse_usu_credito_nuevo = res.data.miSeguro.cse_usu_credito_nuevo;

          this.$store.commit("setplancontratado", res.data.miSeguro);
          this.$store.commit("setestadoconfirmacion", res.data.estadoConfirmacion);
          this.$store.commit("setestadotransaccionpago", true);
          
          this.cambiaMenu(14);
          this.pagado=1;
          this.overlay=false;

          
        }else{
          if(res.data.pag_estado==1){
            this.$alertas(res.data.estadoTransaccion.errors, "warning");
            this.cambiaMenu(14);
            this.pagado=2;
          }else{
            
            this.cambiaMenu(14);
            this.pagado=2;
            this.overlay=false;
          }
        }       
               
      }else{
        this.$store.commit("setselectmenu",3);
        this.$store.commit("setestadoFormCotizacionPrivado", 10);
        this.$router.push("/formulario-cotizacion");
        this.overlay=false;
      }
      
    },
    prefirma(oid){
      this.dialog2=true;
      this.interval = setInterval(() => {
        if (this.value >= 100) {
          this.preparaFirma(oid);
          clearInterval(this.interval);
          return;
        }else{
          this.value += 1;
        }        
      }, 400);

    },
    async  confirmarTransaccion(token_ws){
      this.overlay=true;
      let res = false;
      const data = {
        pag_token: token_ws
      }
      let resp = {
        Vci: "",
        Amount: "",
        Status: "",
        BuyOrder: "",
        SessionId: "",
        CardNumber: "",
        AccountingDate: "",
        TransactionDate: "",
        AuthorizationCode: "",
        PaymentTypeCode: "",
        ResponseCode: 0,
        InstallmentsAmount: 0.0,
        InstallmentsNumber: 0.0,
        Balance: 0.0
      }
      res = await privado.confirmarTransaccion(data);
      if (res.status == 200 && res.data.estadoTransaccion.success) {
        if(res.data.estadoConfirmacion.Status!="FAILED"){
          resp = res.data;
          this.seguro.numero = res.data.miSeguro.cse_numero;
          this.seguro.poliza = res.data.miSeguro.cse_poliza;
          this.seguro.transaccion = res.data.miSeguro.cse_transaccion;
          this.seguro.spl_pln_fecha_inicio = res.data.miSeguro.cse_pln_fecha_inicio;
          this.seguro.spl_pln_fecha_termino = res.data.miSeguro.cse_pln_fecha_termino;
          this.seguro.celular = res.data.miSeguro.cse_celular;
          this.seguro.email = res.data.miSeguro.cse_email;
          this.seguro.direccion = res.data.miSeguro.cse_calle + " #" +
              res.data.miSeguro.cse_nro + ", " + res.data.miSeguro.cse_depto + ", " +
              res.data.miSeguro.cse_comuna + " " + res.data.miSeguro.cse_ciudad;
          this.seguro.nombre = res.data.miSeguro.cse_nombre;
          this.seguro.rut = res.data.miSeguro.cse_rut;
          this.seguro.spl_sim_monto_prima_cl = res.data.miSeguro.cse_sim_monto_prima_cl;
          this.seguro.spl_sim_monto_prima_uf = res.data.miSeguro.cse_sim_monto_prima_uf;
          this.seguro.spl_sim_saldo_insoluto = res.data.miSeguro.cse_sim_monto_credito;
          this.seguro.tipoInstitucion = res.data.miSeguro.cse_glosa_tipo_institucion;
          this.seguro.nombreIntitucion = res.data.miSeguro.cse_glosa_institucion;
          this.seguro.saldoInsoluto = res.data.miSeguro.cse_sim_monto_credito;
          this.seguro.mesesPendientePago = res.data.miSeguro.cse_meses_pendiente;
          this.seguro.periodoGracia = res.data.miSeguro.cse_periodo_gracia;
          this.seguro.plazoCredito = res.data.miSeguro.cse_plazo_meses;
          this.seguro.cantidadCuotas = res.data.miSeguro.cse_meses_pendiente;
          this.seguro.spl_numero = res.data.miSeguro.spl_numero;
          this.seguro.spl_seguro_numero = res.data.miSeguro.spl_seguro_numero;
          this.seguro.cse_pln_numero = res.data.miSeguro.cse_pln_numero;
          this.seguro.cse_numero = res.data.miSeguro.cse_numero;
          this.seguro.cse_ctt_numero = res.data.miSeguro.cse_ctt_numero;
          this.seguro.cse_seg_numero = res.data.miSeguro.cse_seg_numero;
          this.seguro.cse_usu_credito_nuevo = res.data.miSeguro.cse_usu_credito_nuevo;
          this.$store.commit("setplancontratado", res.data.miSeguro);
          this.$store.commit("setestadoconfirmacion", res.data.estadoConfirmacion);
          this.$store.commit("setestadotransaccionpago", true);
          this.cambiaMenu(14);
          this.pagado=1;
          this.overlay=false;
          
        }else{
          this.cambiaMenu(14);
          this.pagado=2;
          this.$store.commit("setestadotransaccionpago", false);
        }        
        this.overlay=false;
      }else{
        this.cambiaMenu(14);
          this.pagado=2;
        this.$store.commit("setestadotransaccionpago", false);
      }
      // this.$store.commit("setselectmenu",0);
      // this.$store.commit("setestadoFormCotizacionPrivado", 10);
      // this.$router.push("/formulario-cotizacion");
      // this.cambiaMenu(3);
      //     this.pagado=2;
      //     this.$store.commit("setestadotransaccionpago", false);
      this.overlay=false;
    },
    async preparaFirma(oid) {
      
      // this.overlay = true;
      
      var res = false;
      var documento = {};
     
        documento = {
          fec_certificado_request_id: oid,
        };
     
      this.$store.commit("settipodocfirma", this.doc_firma);
      
        res = await datausuario.prefirma(documento);
        if (res.status == 200 && res.data.estadoTransaccion.success) {
         
          var firma = {
            urlValidacion: res.data.Url_Validacion,
            oid: res.data.oid,
            exeCode: "",
            seguro: "",
          };
          this.dialog2=false;
          this.$store.commit("setfirmamandato", firma);
          this.dialogFirma = false;
          this.dialog2=false;
          this.overlay = false;
          window.open(res.data.Url_Validacion, "_self");
          this.cambiaMenu(3);
        } else {
          this.$alertas("En este momento no podemos atender su solicitud", "warning");
          this.overlay = false;
          this.dialog2=false;
        }
   
    },
    async firmar(oid,execode) {
      
      this.overlay = true;
      var res = false;
      var documento = {};
     
        documento = {
          oid: oid,
          exe_code: execode
        };
        // this.dialog = true;
      // this.$store.commit("settipodocfirma", this.doc_firma);
      
        res = await datausuario.firmar(documento);
        if (res.status == 200 && res.data.estadoTransaccion.success) {
          
          this.overlay = false;
          this.dialog=true;
          this.cambiaMenu(3);
        } else {
          this.$alertas("En este momento no podemos atender su solicitud", "warning");
          this.overlay = false;
        }
   
    },
  },
  
  metaInfo: {
    title: "Mi Sitio Privado",
  },
  beforeDestroy () {
      // clearInterval(this.interval)
    },
    
  mounted() {
    this.$store.commit("setemptyform1", {});
    this.$store.commit("setemptyform2", {});
    this.$store.commit("setemptyforme1", {});
    this.$store.commit("setemptyforme2", {});
    this.$store.commit("setemptyform3", {});
    this.$store.commit("setemptyform4", {});
    this.$store.commit("setemptyform5", {});
    this.$store.commit("setestadoFormLinea", 1);
    this.$store.commit("setestadoFormCotizacion", 1);
    this.$store.commit("setestadoBps", 1);
    this.$store.commit("setclienteencontrado", false);
    this.$store.commit("settipocotizacion", "inicio");
    this.$store.commit("settipopersonaempresa", "inicio");
    this.$store.commit("setiniciodisabled", true);
    if (this.$root.$refs.LineaForm1) {
      this.$root.$refs.LineaForm1.tipodisabled = true;
    }
    if (this.$root.$refs.CotizaForm1) {
      this.$root.$refs.CotizaForm1.tipodisabled = true;
    }
    if (!localStorage.getItem("loginEstado")) {
      this.$router.push("/login");
    } 
    else {
      if(this.$store.state.selectMenu>1){
      this.cambiaMenu(this.$store.state.selectMenu);
    }
      this.loginEstado = JSON.parse(localStorage.getItem("loginEstado"));
     
      this.loginUsuario = JSON.parse(localStorage.getItem("loginUsuario"));
      
      this.$store.commit("setloginusuario",this.loginUsuario);
      this.fechaAcceso=this.loginUsuario.acc_fecha_ingreso;
      this.ufActual=this.loginUsuario.acc_uf;
      this.nombrelogin = this.loginUsuario.acc_nombre_principal + " " + this.loginUsuario.acc_ape_paterno;
      this.rutEmpresa = this.loginUsuario.contrato.ctt_rut_contrato +"-" +this.loginUsuario.contrato.ctt_dv_contrato;

      let token_ws = this.$route.query.token_ws;
      let tbk_token = this.$route.query.TBK_TOKEN;

      let firma_oid = this.$route.query.oid;
      let tId = this.$route.query.tId;
      let opcancel = this.$route.query.op;
      let retorno = this.$route.query.retorno;

      let oid = this.$route.query.oid;
      let state = this.$route.query.state;
      let execode = this.$route.query.exeCode;
      

      // oid=002EE117-9587-4398-9174-8DEABBC752B6&state=OK&exeCode=cf7c3eaa35354377be0fc570486d3928
      
      let op=true;

        if(tbk_token){
        
          this.confirmarTransaccion(tbk_token);
          op=false;
        }

        if(retorno){
          this.cambiaMenu(3);
        }
        if(tId){
         
          if(opcancel){
            this.cancelarTransaccion(tId);
          }else{
            this.confirmarTransaccion2(tId);
          }
          
          op=false;
        }

        if(token_ws){

          this.confirmarTransaccion(token_ws);
          op=false;
        }

        if(firma_oid && !execode){
          
          // this.preparaFirma(firma_oid);
          this.prefirma(firma_oid)
          op=false;
        }
      if(op){
        this.traeSaldos();
        if(this.$store.state.selectMenu>0){
            this.cambiaMenu(this.$store.state.selectMenu);
        }
      }
      if(oid && state && execode){
        // console.log("firmarmando");
          this.firmar(oid,execode);
      }
       
    }
   
    
    
  },
  created() {
    this.$root.$refs.HomePrivado = this;
  },
};
</script>

<style scoped>
.row{
  margin: 0px !important;
}
.tmpbase {

  background-color: rgba(232, 243, 255, 1) !important;
}

.compa-as-que-nos-respaldan {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h2-font-size, 48px);
  line-height: var(--headings-h2-line-height, 60px);
  font-weight: var(--headings-h2-font-weight, 700);
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.frame-22 {
  flex-shrink: 0;
  width: 100%;
  height: 72px;
  position: relative;
}

.sernac {
  max-width: 200px;
}

@media only screen and (max-width: 1000px) {
  .cmfdr {
    align-content: center;
    justify-content: center;
    display: flex;
  }
  /* .imageBtn{
    margin: 80px !important;
  }
.imageBtnIni{
  margin: 80px !important;
} */
  .rectangle-23801185-jj-79-400-h-1 {
    max-width: 100% !important;
  }

  .comun-cate-con-nosotros-en-servicioalcliente-mueveseguro-cl {
    font-size: var(--headings-h4-font-size, 20px) !important;
  }

  .contratar-seguro {
    display: none !important;
  }

  .menuprivado {
    border-radius: 15px;
    padding: 10px;
    width: 60% !important;
    min-width: 0px !important;
  }

  .bienvenido-a-tu-mi-portal-seguro-para-continuar-con-tu-portabilidad-accede-a-mis-simulaciones-span2 {
    font-size: 17px !important;
  }

  .bienvenido-a-tu-mi-portal-seguro-para-continuar-con-tu-portabilidad-accede-a-mis-simulaciones-span {
    font-size: 23px !important;
  }

  .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin: 0px !important;

  }

}

.rectangle-23801185-jj-79-400-h-1 {
  border-radius: 0px 40px 40px 0px;
  max-width: 520px;
  /* height: 494px; */
  position: relative;
  /* object-fit: cover; */
}

.cmf {
  max-width: 200px;
}

.cmfdr {
  align-content: center;

}

.marcas {
  display: flex;
  justify-content: center;
  border-radius: 20px;
  background-color: white;
}

.franja-ayuda-iz {
  align-content: center;
}

.franja-ayuda {
  background-color: #ffffff !important;
  padding: 20px;
}

.frame-46 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;

}

.necesitas-ayuda {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h2-font-size, 40px);
  line-height: var(--headings-h2-line-height, 60px);
  font-weight: var(--headings-h2-font-weight, 700);
  position: relative;
  width: 100%;

}

.comun-cate-con-nosotros-en-servicioalcliente-mueveseguro-cl {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h4-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h4-font-size, 28px);
  line-height: var(--headings-h4-line-height, 36px);
  font-weight: var(--headings-h4-font-weight, 400);
  position: relative;
  width: 100%;
  /* height: 81px; */
}

.banner-principal2 {
  background: #ffffff;
  padding: 27px 360px 27px 360px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;

  position: relative;

  overflow: hidden;
}

.frame-22 {
  flex-shrink: 0;
  width: 100%;
  height: 72px;
  position: relative;
}

.colcenter {
  display: flex;
  justify-content: center;
}

.bannerlogo {
  background-image: url('/assets/privado/teaser-ms-02-10.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  margin-left: 0px !important;
}

.bannerlogo2 {
  
  background-size: 100% auto;
 
}
.bannerlogo3 {
  
  background-size: 100% 100%;
 
}

.menuprivado {
  border-radius: 15px;
  padding: 10px;
}

.nombreMenu {
  color: #04137F !important;
  font-size: 20px !important;
  /* font-family: Circular Pro !important; */
  font-weight: 450 !important;
  line-height: 24px !important;
}

.btnMenu {
  max-width: 100% !important;
  margin-top: -10px;
  color: #04137F !important;
  font-size: 20px !important;
  font-family: Circular Pro !important;
  font-weight: 450 !important;
  line-height: 24px !important;
  word-wrap: break-word !important;
  min-width: 0px !important;
}
.btnMenu2 {
  max-width: 100% !important;
  margin-top: -10px;
  color: #1d2eff !important;
  font-size: 20px !important;
  font-family: Circular Pro !important;
  font-weight: 450 !important;
  line-height: 24px !important;
  word-wrap: break-word !important;
  min-width: 0px !important;
}


@media only screen and (max-width: 1380px) {


  .bannerlogo {
    background-image: url('/assets/privado/teaser-ms-02-10.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-left: 0px !important;
  }
}

.nastyle {
  width: 100%;
}

.contratar-seguro {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 15px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
  display: inline;

  align-self: center;
}

.imageBtn {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
  display: inline;
  width: 60%;
  margin-top: 6px;
}

.frame-44 {
  display: flex;
}

.imageBtnIni {
  width: 60%;
}

.happy-young-white-couple-looking-at-laptop-compute-2023-11-27-05-20-49-utc-1 {
  border-radius: 0px 40px 40px 0px;
  width: 100%;
  height: 100%;
  /* height: 220px; */
}

.bienvenido-a-tu-mi-portal-seguro-para-continuar-con-tu-portabilidad-accede-a-mis-simulaciones {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  position: relative;
  width: 100%;
  /* height: 125px; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.bienvenido-a-tu-mi-portal-seguro-para-continuar-con-tu-portabilidad-accede-a-mis-simulaciones-span {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h3-font-family, "CircularPro-Medium", sans-serif);
  font-size: var(--headings-h3-font-size, 35px);
  line-height: var(--headings-h3-line-height, 48px);
  font-weight: var(--headings-h3-font-weight, 500);
}

.bienvenido-a-tu-mi-portal-seguro-para-continuar-con-tu-portabilidad-accede-a-mis-simulaciones-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 20px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
}

.si-tienes-un-seguro-de-desgravamen-asociado-a-un-cr-dito-de-consumo-automotriz-o-comercial-pyme-y-quieres-portarte-y-recuperar-dinero-d-janos-tus-datos {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 24px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.excluye-cr-ditos-hipotecarios {
  color: rgba(4, 19, 127, 1) !important;
  text-align: center;
  font-family: var(--headings-h7-font-family, "CircularPro-Black", sans-serif);
  font-size: var(--headings-h7-font-size, 16px);
  line-height: var(--headings-h7-line-height, 24px);
  font-weight: var(--headings-h7-font-weight, 900);
  position: relative;
  /* margin-top: 30px; */
  /* width: 400px; */
}
.quieres-portar-tu-seguro-de-desgravamen {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h2-font-size, 38px);
  line-height: var(--headings-h2-line-height, 50px);
  font-weight: var(--headings-h2-font-weight, 700);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fondoDialog{
  background : white !important;
}

.dialogFirma{
 
  color: #7130ed;
    line-height: 37px;
    font-size: 30px;
    text-transform: unset !important;
}
.dialogFirma2{
 
 color: #7130ed;
   line-height: 28px;
   font-size: 25px;
   text-transform: unset !important;
}
.headDialog{
  background-color: rgb(213, 209, 209) !important;;
    color: var(--azul-complementario, #04137f)  !important;;
    font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif) !important;;
    font-size: var(--headings-h5-font-size, 24px) !important;;
    line-height: var(--headings-h5-line-height, 28px) !important;;
    font-weight: var(--headings-h5-font-weight, 400) !important;;
}
</style>